import React from 'react';
import './App.css';
import Header from "./header/Header";
import MainContainer from "./components/MainContainer";
import Footer from "./footer/Footer";
import {BrowserRouter as Router} from "react-router-dom";
import {StateProvider} from "./store";

function App() {
    return (
        <Router>
            <StateProvider>
                <div className="App">
                    <Header/>
                    <MainContainer/>
                    <Footer/>
                </div>
            </StateProvider>
        </Router>
    );
}

export default App;
