import React from "react";
import {Spinner} from "react-bootstrap";

export const getLeagueNamesFromSeasons = (seasons) => {
    const leagueNames = new Set();

    let id;
    for (id in seasons) {
        if (seasons[id].leagues && seasons[id].leagues.length !== 0) {
            const names = seasons[id].leagues.map(league => league.name);
            let nameId;
            for (nameId in names) {
                leagueNames.add(names[nameId]);
            }
        }
    }
    return leagueNames;
};

export const getUrlVars = () => {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
};

export const createTeamNameOptions = (allTeams) => {
    const options = [<option key={"empty-option"} value=""/>];
    allTeams.sort((a, b) => a.name.localeCompare(b.name, 'et'));

    const existingOptions = [...allTeams].map(team => <option key={team.id + "-option"}
                                                              value={team.id}>
        {getTeamNameOptionsText(team)}
    </option>);
    options.push(existingOptions);
    return options;
};

export const renderSpinner = () => {
    return (<Spinner animation="border" role="status">
        <span className="sr-only">Laadimine...</span>
    </Spinner>);
};

const getTeamNameOptionsText = (team) => {
    const teamPlayersString = team.players && team.players.length !== 0
        ? `(${team.players.map(player => player.firstName + " " + player.lastName)
            .join(", ")})`
        : '(mängijad puuduvad)';
    return `${team.name} ${teamPlayersString}`;
};

export const hasNumberDuplicates = (array) => {
    const numberArray = array.map(el => Number(el));
    return (new Set(numberArray)).size !== array.length;
}

export const round = (value, precision) => {
    const multiplier = Math.pow(10, precision);
    return (Math.round(value * multiplier) / multiplier);
}
