import React, {useContext, useEffect, useState} from "react";
import {store} from "../store";
import {API} from "../api";
import DataTable from "../modules/datatable/Datatable";
import {Tab, Tabs} from "react-bootstrap";
import {renderSpinner} from "../util/util";
import '../style/Tabs.css';

export const Statistics = () => {
    const [seasons, setSeasons] = useState([]);
    const [currentSeason, setCurrentSeason] = useState({});
    const [playerStatistics, setPlayerStatistics] = useState();
    const [teamStatistics, setTeamStatistics] = useState();
    const [currentLeague, setCurrentLeague] = useState();
    const globalState = useContext(store);
    const {dispatch} = globalState;
    const [loading, setLoading] = useState({playerStatistics: false, teamStatistics: false, seasons: false});

    useEffect(() => {
        setLoading(l => {
            return {...l, seasons: true}
        });
        API.get(`/season/seasons`).then(res => {
            res.data.sort((a, b) => b.name.localeCompare(a.name, 'et'));
            res.data.forEach(season => {
                season.leagues.sort((a, b) => b.name.localeCompare(a.name, 'et'));
            })
            setSeasons(res.data);
            if (globalState.state.selectedSeason && globalState.state.selectedLeague) {
                const updatedCurrentSeason = res.data.find(session => session.id === globalState.state.selectedSeason.id);
                const updatedCurrentLeague = updatedCurrentSeason.leagues.find(league => league.id === globalState.state.selectedLeague);
                setCurrentSeason(updatedCurrentSeason);
                setCurrentLeague(updatedCurrentLeague ? updatedCurrentLeague.id : null);
            } else {
                const newCurrentSeason = res.data[0];
                const newCurrentLeague = newCurrentSeason && newCurrentSeason.leagues.length ? newCurrentSeason.leagues[0].id : 0;
                setCurrentSeason(newCurrentSeason);
                setCurrentLeague(newCurrentLeague);
            }

        }).finally(() => setLoading(l => {
            return {...l, seasons: false}
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentSeason && currentSeason.id) {
            setLoading(l => {
                return {...l, playerStatistics: true, teamStatistics: true}
            });
            API.get(`/statistics/players/${currentSeason.id}`).then(res => {
                setPlayerStatistics(res.data);
            }).finally(() => setLoading(l => {
                return {...l, playerStatistics: false}
            }));
            API.get(`/statistics/teams/${currentSeason.id}`).then(res => {
                setTeamStatistics(res.data);
            }).finally(() => setLoading(l => {
                return {...l, teamStatistics: false}
            }));
        }
    }, [currentSeason]);

    const handleSeasonChanged = (e) => {
        setPlayerStatistics(null);
        setTeamStatistics(null);
        const season = seasons.find(session => session.id === Number(e.target.value));
        setCurrentSeason(season);
        if (season.leagues.length) {
            setCurrentLeague(season.leagues[0].id);
            dispatch({
                type: 'SET_SELECTED_STATE',
                state: {selectedSeason: season, selectedLeague: season.leagues[0].id}
            });
        } else {
            dispatch({type: 'SET_SELECTED_STATE', state: {selectedSeason: season, selectedLeague: currentLeague}});
        }
    };

    const handleLeagueChanged = (e) => {
        dispatch({
            type: 'SET_SELECTED_STATE',
            state: {selectedSeason: currentSeason, selectedLeague: Number(e)}
        });
        setCurrentLeague(Number(e));
    };

    return (
        <div className={"statistics_wrapper"}>
            {loading.seasons && renderSpinner()}
            {seasons &&
            <div id="select_season">
                <select className="form-control"
                        value={currentSeason ? currentSeason.id : 0}
                        onChange={handleSeasonChanged}>
                    {seasons.map((season) => <option key={season.id} value={season.id}>{season.name}</option>)}
                </select>
            </div>
            }
            {(loading.playerStatistics || loading.teamStatistics) && renderSpinner()}
            {currentSeason && currentSeason.leagues &&

            <Tabs className="nav-tabs statistics-tabs" activeKey={currentLeague} onSelect={handleLeagueChanged}
                  id="statistics-tabs">
                {currentSeason.leagues.map(league =>
                    <Tab key={league.id} eventKey={league.id} title={league.name}>
                        {playerStatistics && teamStatistics &&
                        <Tabs className="nav_tabs average-tabs"
                              defaultActiveKey="playerStatisticsGeneral"
                              id="average-tabs">
                            <Tab key="playerStatisticsGeneral" eventKey="playerStatisticsGeneral"
                                 title="Mängijad">
                                <DataTable
                                    playerStatistics={playerStatistics.find(s => s.leagueId === currentLeague).playerStatisticsList}
                                    season_id={currentSeason.id}
                                    searching={true}
                                    sortable={false}
                                />
                            </Tab>
                            <Tab key="playerStatisticsDetail" eventKey="playerStatisticsDetail"
                                 title="Mängijad (detailne)">
                                <DataTable
                                    playerStatistics={playerStatistics.find(s => s.leagueId === currentLeague).playerStatisticsList}
                                    season_id={currentSeason.id}
                                    detailView={true}
                                    searching={true}
                                    sortable={true}
                                />
                            </Tab>
                            <Tab key="teamStatistics" eventKey="teamStatistics" title="Võistkonnad">
                                <DataTable
                                    teamStatistics={teamStatistics.find(s => s.leagueId === currentLeague).roundTeamStatisticsList}
                                    season_id={currentSeason.id}
                                    searching={true}
                                    sortable={true}
                                />
                            </Tab>
                        </Tabs>
                        }
                    </Tab>)}
            </Tabs>
            }
        </div>);
};
