import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../../style/TableActionItems.css';
import {faPlayCircle} from "@fortawesome/free-solid-svg-icons/faPlayCircle";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export const CalendarButton = (props) => {
    function getCalendarIcon() {
        return <>
            {props.hasCalendar
                ? <FontAwesomeIcon icon={faCheck}/>
                :
                <>
                    <OverlayTrigger
                        key="trigger"
                        placement="left"
                        overlay={
                            <Tooltip id={`tooltip-trigger`}>
                                Genereeri liiga kalender
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon className="action" icon={faPlayCircle} onClick={props.generateAction}/>
                    </OverlayTrigger>
                </>

            }
        </>;
    }

    return (
        <div>
            {props.numberOfTeams >= 2 ? getCalendarIcon() : ""}
        </div>);
};