import React, {Component} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {API} from "../api";
import DataTable from "./datatable/Datatable";
import {renderSpinner} from "../util/util";
import '../style/RecordsTable.css';
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {createDateFromBackendDate, createDateTimeFromBackendDate, getCurrentDateWithZeroTime} from "../util/dateUtil";

class MatchDays extends Component {
    state = {
        matchDays: [],
        topPlayers: [],
        records: [],
        loading: {matchDays: false, topPlayers: false, roundRecords: false}
    };

    componentDidMount() {
        this.setState(prevState => ({loading: {...prevState.loading, matchDays: true}}));
        this.setState(prevState => ({loading: {...prevState.loading, topPlayers: true}}));
        this.setState(prevState => ({loading: {...prevState.loading, roundRecords: true}}));
        API.get(`/league/${this.props.leagueId}/match-days`).then(res => {
            this.setState({matchDays: res.data});
        }).finally(() => this.setState(prevState => ({loading: {...prevState.loading, matchDays: false}})));
        API.get(`/statistics/${this.props.leagueId}/top-players`).then(res => {
            this.setState({topPlayers: res.data});
        }).finally(() => this.setState(prevState => ({loading: {...prevState.loading, topPlayers: false}})));
        API.get(`/statistics/${this.props.leagueId}/round-records`).then(res => {
            this.setState({records: res.data.records});
        }).finally(() => this.setState(prevState => ({loading: {...prevState.loading, roundRecords: false}})));
    }

    latestActiveDay = (matchDays) => {
        let highestMatchVal = 0;

        Object.entries(matchDays)
            .sort(([a], [b]) => b - a)
            .forEach(([_, element]) => {
                if (this.containsAtLeastOnePlayedMatch(element) && element.id > highestMatchVal) {
                    highestMatchVal = element.id;
                }
            });

        return highestMatchVal;
    };

    currentlyActiveMatch = (matchDays) => {
        let highestMatchVal = 0;
        const currentDate = getCurrentDateWithZeroTime();

        Object.entries(matchDays)
            .sort(([a], [b]) => b - a)
            .forEach(([_, element]) => {
                if (createDateFromBackendDate(element.matchDayStartDate) <= currentDate
                    && createDateFromBackendDate(element.matchDayEndDate) >= currentDate
                    && element.id > highestMatchVal) {
                    highestMatchVal = element.id;
                }
            });

        return highestMatchVal;
    };

    render() {
        const {matchDays, topPlayers, records} = this.state;
        if (this.state.loading.matchDays || this.state.loading.topPlayers || this.state.loading.roundRecords) {
            return <div className="mt-5">{renderSpinner()}</div>;
        }
        if (!this.props.leagueId || !matchDays.length || !records.length) {
            return null;
        }

        return (
            <Tabs className="nav_tabs nav_tabs_clickable"
                  defaultActiveKey={this.currentlyActiveMatch(matchDays)}
                  id="match-day-tabs">
                {matchDays.map(matchDay =>
                    <Tab key={matchDay.id} eventKey={matchDay.id} title={matchDay.matchDayNo}
                         disabled={false}>
                        <Row>
                            <Col lg={{span: 12, order: 1}} xs={{span: 12, order: 1}}><DataTable matchDayMatches={matchDay.matches}/></Col>
                            <Col lg={{span: 6, order: 2}} xs={{span: 12, order: 2}}><h6>Vooru TOP</h6></Col>
                            <Col lg={{span: 6, order: 3}} xs={{span: 12, order: 4}}><h6>Rekordid</h6></Col>
                            <Col lg={{span: 6, order: 4}} xs={{span: 12, order: 3}}><DataTable topPlayers={topPlayers.find(e => e.roundNo === matchDay.matchDayNo)}/></Col>
                            <Col lg={{span: 6, order: 5}} xs={{span: 12, order: 5}}>{this.renderRoundRecords(records, matchDay)}</Col>
                        </Row>
                    </Tab>
                )}
            </Tabs>
        );
    }

    renderRoundRecords(records, matchDay) {
        const roundRecord = records.find(e => e.roundNo === matchDay.matchDayNo && e.seasonId === this.props.currentSeason.id);
        if (!roundRecord) {
            return null;
        }
        return (
            <MDBTable striped className="recordsTable">
                <MDBTableHead>
                    <tr>
                        <th>Kodus</th>
                        <th/>
                        <th>Võõrsil</th>
                        <th/>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    <tr>
                        <td>{roundRecord.homeSetRecordPlayerName}</td>
                        <td>{roundRecord.homeSetRecordScore}</td>
                        <td>{roundRecord.awaySetRecordPlayerName}</td>
                        <td>{roundRecord.awaySetRecordScore}</td>

                    </tr>
                    <tr>
                        <td>{roundRecord.homeRoundRecordTeamName}</td>
                        <td>{roundRecord.homeRoundRecordScore}</td>
                        <td>{roundRecord.awayRoundRecordTeamName}</td>
                        <td>{roundRecord.awayRoundRecordScore}</td>
                    </tr>
                </MDBTableBody>
            </MDBTable>
        );
    }

    containsAtLeastOnePlayedMatch(matchDay) {
        return matchDay.matches.find(match => match.homePoints !== null && match.awayPoints !== null
            && match.homeTotal !== null && match.awayTotal !== null);
    }
}

export default MatchDays;
