import React, {Component} from 'react';
import {MDBDataTable} from 'mdbreact';
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {TableActionItems} from "./TableActionItems";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {CalendarButton} from "./CalendarButton";
import '../../style/DataTable.css';
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {
    createDateFromBackendDate,
    createDateTimeFromBackendDate,
    createNullableDateTimeFromBackendDate,
    formatBackendDateEtLocale,
    formatBackendDateTimeEtLocale, getCurrentDateWithZeroTime
} from "../../util/dateUtil";
import {round} from "../../util/util";
import {CalendarSecondRoundButton} from "./CalendarRoundTwoButton";


class Datatable extends Component {

    getTeamsData = () => {
        let rowData = [];
        this.props.teams.map((row) =>
            rowData.push({
                position: row.seasonScore ? row.seasonScore.position : '?',
                name: row.name,
                games: row.seasonScore ? row.seasonScore.gamesPlayed : 0,
                winnings: row.seasonScore ? row.seasonScore.gamesWon : 0,
                losses: row.seasonScore ? row.seasonScore.gamesLost : 0,
                tied: row.seasonScore ? row.seasonScore.gamesTied : 0,
                points: row.seasonScore ? row.seasonScore.points : 0,
                mp: row.seasonScore ? `${row.seasonScore.gamePointsWon}:${row.seasonScore.gamePointsLost}` : 0,
                average: row.seasonScore ? round(row.seasonScore.average, 1) : 0,
                best: row.seasonScore ? row.seasonScore.best : 0,
                toDetails: <Link
                    to={`/team_details?team_id=${row.id}&season_id=${this.props.seasonId}`}><FontAwesomeIcon
                    icon={faEye}/></Link>,
            })
        );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Koht',
                    field: 'position',
                },
                {
                    label: 'Nimi',
                    field: 'name',
                },
                {
                    label: 'Mängud',
                    field: 'games',
                },
                {
                    label: 'Võidud',
                    field: 'winnings',
                },
                {
                    label: 'Kaotused',
                    field: 'losses',
                },
                {
                    label: 'Viigid',
                    field: 'tied',
                },
                {
                    label: 'Punktid',
                    field: 'points',
                },
                {
                    label: 'MP',
                    field: 'mp',
                },
                {
                    label: 'Keskmine',
                    field: 'average',
                },
                {
                    label: 'Parim',
                    field: 'best',
                },
                {
                    label: 'Detailid',
                    field: 'toDetails',
                },
            ],
            rows: rowData.sort((a, b) => {
                if ((a.position === '?' && b.position === '?')) {
                    return a.name.localeCompare(b.name, 'et');
                }
                return (a.position !== '?' ? a.position : Infinity) - (b.position !== '?' ? b.position : Infinity);
            })
        };
    };

    getUsersData = () => {
        let rowData = [];
        this.props.users.map((row) =>
            rowData.push({
                username: row.username ? row.username : 'N/A',
                isAdmin: !!row.isAdmin ? 'Jah' : 'Ei',
                representedTeamName: row.representedTeamName ? row.representedTeamName : 'N/A',
                actions: !!row.isAdmin ? '' : <TableActionItems deleteAction={() => this.props.deleteAction(row.id)}/>
            })
        );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Kasutajanimi',
                    field: 'username',
                    sort: 'asc',
                }, {
                    label: 'Esindatav võistkond',
                    field: 'representedTeamName',
                }, {
                    label: 'Administraator',
                    field: 'isAdmin',
                }, {
                    label: 'Kustuta',
                    field: 'actions'
                }
            ],
            rows: rowData
        };
    };

    getClubsData = () => {
        let rowData = [];
        let totalTeams = 0;
        this.props.clubs.map((row) => {
            let clubTeams = 0;
            if (row.teams != null) {
                for (const team of row.teams) {
                    if (team.players != null && team.players.length > 0) {
                        clubTeams++;
                        totalTeams++;
                    }
                }
            }
            return rowData.push({
                  name: row.name ? row.name : 'N/A',
                  numberOfTeams: clubTeams,
                  numberOfPlayers: row.teams && row.teams.length > 0 ? row.teams.map(team => team.players).reduce(this.reducePlayercount, 0) : 0
              });
          }
        );
        if (!rowData.length) {
            return null;
        }

        rowData.sort((a, b) => a.name.localeCompare(b.name, 'et'));

        rowData.push({
            name: 'Kokku',
            numberOfTeams: totalTeams,
            numberOfPlayers: this.props.clubs ? this.props.clubs.reduce(function (total, club) {
                return total + (club.teams ? club.teams.reduce(function (total, team) {
                    return total + (team.players ? team.players.length : 0);
                }, 0) : 0);
            }, 0) : 0
        });

        return {
            columns: [
                {
                    label: 'Nimi',
                    field: 'name',
                }, {
                    label: 'Võistkondade arv',
                    field: 'numberOfTeams',
                }, {
                    label: 'Mängijate arv',
                    field: 'numberOfPlayers'
                }
            ],
            rows: rowData
        };
    };

    getClubMembersData = () => {
        let rowData = [];
        this.props.clubMembers.map((row) =>
            rowData.push({
                clubName: row.clubName ? row.clubName : 'N/A',
                playerName: row.playerName ? row.playerName : 'N/A',
                teamName: row.teamName ? row.teamName : 'N/A',
                isCaptain: row.isCaptain ? '(K)' : '',
                competitionClass: row.competitionClass ? row.competitionClass : ''
            })
        );
        if (!rowData.length) {
            return null;
        }

        rowData.sort((first, second) => {
            if (first.clubName === second.clubName) {
                if (first.teamName === second.teamName) {
                    return first.playerName.localeCompare(second.playerName, 'et');
                }
                return first.teamName.localeCompare(second.teamName, 'et');
            }
            return first.clubName.localeCompare(second.clubName, 'et');
        });

        return {
            columns: [
                {
                    label: 'Klubi',
                    field: 'clubName',
                }, {
                    label: 'Liige',
                    field: 'playerName',
                }, {
                    label: 'Võistkond',
                    field: 'teamName',
                }, {
                    label: 'Kapten',
                    field: 'isCaptain'
                }, {
                    label: 'Võistlusklass',
                    field: 'competitionClass'
                }
            ],
            rows: rowData
        };
    };

    reducePlayercount(total, players) {
        return total + players.length;
    }

    getMatchesData = () => {
        let rowData = [];
        this.props.matches
                .sort((a, b) => createDateTimeFromBackendDate(a.matchDateTime) > createDateTimeFromBackendDate(b.matchDateTime) ? 1 : -1)
                .map((row) =>
                        rowData.push({
                            homeTeamName: row.homeTeamName ? this.setBoldIfCurrentTeam(this.props.currentTeamName, row.homeTeamName) : 'N/A',
                            awayTeamName: row.awayTeamName ? this.setBoldIfCurrentTeam(this.props.currentTeamName, row.awayTeamName) : 'N/A',
                            matchDateTime: row.matchDateTime ? formatBackendDateEtLocale(row.matchDateTime) : 'N/A',
                            combinedPoints: this.isPlayed(row) ? this.colorCodeBasedOnTeamResult(row, this.props.currentTeamName, row.homeTeamName) : '',
                            toDetails: this.isPlayed(row) ? <Link to={`/match_details?match_id=${row.id}`}><FontAwesomeIcon icon={faEye}/></Link> : '',
                        })
                );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Koduvõistkond',
                    field: 'homeTeamName',
                },
                {
                    label: 'Külalisvõistkond',
                    field: 'awayTeamName',
                },
                {
                    label: 'Aeg',
                    field: 'matchDateTime',
                },
                {
                    label: 'Tulemus',
                    field: 'combinedPoints',
                },
                {
                    label: 'Detailid',
                    field: 'toDetails',
                },
            ],
            rows: rowData
        };
    };

    getMatchDayMatchesData = () => {
        let rowData = [];
        this.props.matchDayMatches
            .sort((first, second) => createDateFromBackendDate(first.matchDateTime == null ? getCurrentDateWithZeroTime() : first.matchDateTime) - createDateFromBackendDate(second.matchDateTime == null ? getCurrentDateWithZeroTime() : second.matchDateTime))
            .map((row) =>
                rowData.push({
                    homeTeamName: row.homeTeamName ? this.setBoldIfCurrentTeam(this.props.currentTeamName, row.homeTeamName) : 'N/A',
                    awayTeamName: row.awayTeamName ? this.setBoldIfCurrentTeam(this.props.currentTeamName, row.awayTeamName) : 'N/A',
                    matchDateTime: row.matchDateTime ? formatBackendDateEtLocale(row.matchDateTime) : 'N/A',
                    combinedPoints: this.isPlayed(row) ? `${row.homePoints}:${row.awayPoints} (${row.homeTotal}:${row.awayTotal})` : '',
                    toDetails: this.isPlayed(row) ? <Link to={`/match_details?match_id=${row.id}`}><FontAwesomeIcon icon={faEye}/></Link> : '',
                })
            );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Koduvõistkond',
                    field: 'homeTeamName',
                },
                {
                    label: 'Külalisvõistkond',
                    field: 'awayTeamName',
                },
                {
                    label: 'Aeg',
                    field: 'matchDateTime',
                },
                {
                    label: 'Tulemus',
                    field: 'combinedPoints',
                },
                {
                    label: 'Detailid',
                    field: 'toDetails',
                },
            ],
            rows: rowData
        };
    };

    colorCodeBasedOnTeamResult(row, currentTeamName, homeTeamName) {
        const isHome = currentTeamName === homeTeamName;
        if ((isHome && row.homePoints > row.awayPoints) || (!isHome && row.homePoints < row.awayPoints)) {
            return <div style={{color: "green", fontWeight: 800}}>{row.homePoints}:{row.awayPoints} ({row.homeTotal}:{row.awayTotal})</div>;
        }
        if ((isHome && row.homePoints < row.awayPoints) || (!isHome && row.homePoints > row.awayPoints)) {
            return <div style={{color: "red", fontWeight: 800}}>{row.homePoints}:{row.awayPoints} ({row.homeTotal}:{row.awayTotal})</div>;
        }
        return <div style={{fontWeight: 800}}>{row.homePoints}:{row.awayPoints} ({row.homeTotal}:{row.awayTotal})</div>;
    }

    setBoldIfCurrentTeam(currentTeam, teamFromData) {
        return currentTeam === teamFromData ? <div style={{fontWeight: 800}}>{teamFromData}</div> : teamFromData;
    }

    isPlayed(row) {
        return row.homePoints !== null && row.awayPoints !== null && row.homeTotal !== null && row.awayTotal !== null;
    }

    getFutureConfirmedMatchesData = () => {
        let rowData = [];
        this.props.futureMatches
            .sort((first, second) => createDateTimeFromBackendDate(first.matchDateTime) - createDateTimeFromBackendDate(second.matchDateTime))
            .map(row =>
                rowData.push({
                    leagueName: row.leagueName ? row.leagueName : 'N/A',
                    homeTeamName: row.homeTeamName ? row.homeTeamName : 'N/A',
                    awayTeamName: row.awayTeamName ? row.awayTeamName : 'N/A',
                    matchDateTime: row.matchDateTime ? formatBackendDateTimeEtLocale(row.matchDateTime) : 'N/A'
                })
            );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Liiga',
                    field: 'leagueName',
                },
                {
                    label: 'Koduvõistkond',
                    field: 'homeTeamName',
                },
                {
                    label: 'Külalisvõistkond',
                    field: 'awayTeamName',
                },
                {
                    label: 'Toimumise aeg',
                    field: 'matchDateTime',
                }
            ],
            rows: rowData
        };
    };

    get5UpcomingMatchesBasedOnSelectedLeague = () => {
        let rowData = [];
        this.props.futureMatches
            .sort((first, second) => createDateTimeFromBackendDate(first.matchDateTime) - createDateTimeFromBackendDate(second.matchDateTime))
            .filter(match => match.leagueId === this.props.leagueId)
            .slice(0, 5)
            .map(row =>
                rowData.push({
                    leagueName: row.leagueName ? row.leagueName : 'N/A',
                    homeTeamName: row.homeTeamName ? row.homeTeamName : 'N/A',
                    awayTeamName: row.awayTeamName ? row.awayTeamName : 'N/A',
                    matchDateTime: row.matchDateTime ? formatBackendDateTimeEtLocale(row.matchDateTime) : 'N/A'
                })
            );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Liiga',
                    field: 'leagueName',
                },
                {
                    label: 'Koduvõistkond',
                    field: 'homeTeamName',
                },
                {
                    label: 'Külalisvõistkond',
                    field: 'awayTeamName',
                },
                {
                    label: 'Toimumise aeg',
                    field: 'matchDateTime',
                }
            ],
            rows: rowData
        };
    };

    getAllMatchesData = () => {
        let rowData = [];
        this.props.allMatches
            .sort((first, second) => createNullableDateTimeFromBackendDate(first.matchDateTime) - createNullableDateTimeFromBackendDate(second.matchDateTime))
            .forEach((row) => {
                    rowData.push({
                        leagueName: row.leagueName ? row.leagueName : 'N/A',
                        seasonName: row.seasonName ? row.seasonName : 'N/A',
                        homeTeamName: row.homeTeamName ? row.homeTeamName : 'N/A',
                        awayTeamName: row.awayTeamName ? row.awayTeamName : 'N/A',
                        matchDateTime: row.matchDateTime ? formatBackendDateEtLocale(row.matchDateTime) : 'N/A',
                        combinedPoints: row.homePoints !== null ? `${row.homePoints}:${row.awayPoints} (${row.homeTotal}:${row.awayTotal})` : 'N/A',
                        toDetails: row.homePoints !== null
                            ? <Link to={`/admin/match_details?match_id=${row.id}`}><FontAwesomeIcon icon={faEye}/></Link>
                            : <Link to={`/admin/add_match?match_id=${row.id}`}><FontAwesomeIcon icon={faEdit}/></Link>,
                    })
                }
            );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Hooaeg',
                    field: 'seasonName',
                },
                {
                    label: 'Liiga',
                    field: 'leagueName',
                },
                {
                    label: 'Koduvõistkond',
                    field: 'homeTeamName',
                },
                {
                    label: 'Külalisvõistkond',
                    field: 'awayTeamName',
                },
                {
                    label: 'Aeg',
                    field: 'matchDateTime',
                },
                {
                    label: 'Tulemus',
                    field: 'combinedPoints',
                },
                {
                    label: 'Detailid',
                    field: 'toDetails',
                },
            ],
            rows: rowData
        };
    };

    getSeasonsData() {
        let rowData = [];
        const leagueNameReducer = (accumulator, currentValue) => accumulator + ", " + currentValue;

        this.props.seasons.map((row) =>
            rowData.push({
                name: row.name ? row.name : 'N/A',
                startDate: row.startDate ? formatBackendDateEtLocale(row.startDate) : 'N/A',
                endDate: row.endDate ? formatBackendDateEtLocale(row.endDate) : 'N/A',
                leagueNames: (row.leagues && row.leagues.length !== 0) ? row.leagues.map(league => league.name).reduce(leagueNameReducer) : 'N/A',
                actions: <TableActionItems editAction={() => this.props.editAction(row.id)}/>
            })
        );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Nimi',
                    field: 'name',
                },
                {
                    label: 'Algus',
                    field: 'startDate',
                },
                {
                    label: 'Lõpp',
                    field: 'endDate',
                },
                {
                    label: "Liiga(d)",
                    field: 'leagueNames'
                },
                {
                    label: '',
                    field: 'actions',
                }
            ],
            rows: rowData
        };
    }

    getActionableTeamData() {
        let rowData = [];
        this.props.actionableTeams.map((row) =>
            rowData.push({
                name: row.name ? row.name : 'N/A',
                clubName: row.clubName ? row.clubName : 'N/A',
                numberOfPlayers: row.players.length,
                actions: <TableActionItems editAction={() => this.props.editAction(row.id)}/>
            })
        );
        if (!rowData.length) {
            return null;
        }

        return {
            columns: [
                {
                    label: 'Nimi',
                    field: 'name',
                },
                {
                    label: 'Klubi nimi',
                    field: 'clubName',
                },
                {
                    label: 'Mängijate arv',
                    field: 'numberOfPlayers'
                },
                {
                    label: '',
                    field: 'actions',
                }
            ],
            rows: rowData
        };
    }

    getLeaguesData() {
        let rowData = [];
        this.props.leagues.map((row) =>
            rowData.push({
                name: row.name ? row.name : 'N/A',
                season: row.seasonStart ? formatBackendDateEtLocale(row.seasonStart) + ' - ' + formatBackendDateEtLocale(row.seasonEnd) : 'N/A',
                numberOfTeams: row.teams.length,
                actions: <TableActionItems editAction={() => this.props.editAction(row.id)}/>,
                calendar: <CalendarButton hasCalendar={row.hasCalendar}
                                          numberOfTeams={row.teams.length}
                                          generateAction={() => this.props.generateAction(row.id)}/>,
                calendarSecondRound: <CalendarSecondRoundButton hasCalendarSecondRound={row.hasCalendarSecondRound}
                                          numberOfTeams={row.teams.length}
                                          generateAction={() => this.props.generateSecondRoundAction(row.id)}/>
            })
        );
        if (!rowData.length) {
            rowData = [{name: '',}]
        }

        return {
            columns: [
                {
                    label: 'Nimi',
                    field: 'name',
                }, {
                    label: 'Hooaeg',
                    field: 'season',
                }, {
                    label: 'Võistkondade arv',
                    field: 'numberOfTeams'
                }, {
                    label: 'Kalender',
                    field: 'calendar',
                }, {
                    label: 'Kalender II voor',
                    field: 'calendarSecondRound',
                }, {
                    label: '',
                    field: 'actions',
                }
            ],
            rows: rowData
        };
    }

    getPlayerStatisticsData() {
        let rowData = [];
        let matchDaysLength = 0;
        if (this.props.playerStatistics.length) {
            matchDaysLength = this.props.playerStatistics[0].gamePinsTotalByRound.length;
        }
        this.props.playerStatistics.sort((first, second) => second.gamePinsTotalAverage - first.gamePinsTotalAverage);

        this.props.playerStatistics.forEach(function(row, index) {
            row.row_number = index+1;
        });

        this.props.playerStatistics.map((row) => {
                let row_data_var = {
                    orderNo: row.row_number,
                    name: row.name,
                    club: row.clubName,
                    gamePinsTotalAverage: row.gamePinsTotalAverage !== -1 ?
                        <div style={{color: this.setColor(row.gamePinsTotalAverage), fontWeight: 800}}>{round(row.gamePinsTotalAverage, 1)}</div> : '',
                    bestGameTotalPins: row.bestGameTotalPins !== -1 ?
                        <div style={{color: this.setColor(row.bestGameTotalPins), fontWeight: 800}}>{row.bestGameTotalPins}</div> : '',
                    playedRounds: row.playedRounds
                };
                for (let i = 0; i < matchDaysLength; i++) {
                    row_data_var['round-' + i] = <div style={{color: this.setColor(row.gamePinsTotalByRound[i])}}>{row.gamePinsTotalByRound[i]}</div>;
                }
                rowData.push(row_data_var);
                return rowData;
            }
        );

        if (!rowData.length) {
            rowData = [{name: '',}]
        }

        let columns = [
            {
                label: 'Jrk. nr',
                field: 'orderNo'
            },
            {
                label: 'Nimi',
                field: 'name',
            }, {
                label: 'Klubi',
                field: 'club',
            }, {
                label: 'Keskmine tulemus',
                field: 'gamePinsTotalAverage'
            }, {
                label: 'Parim',
                field: 'bestGameTotalPins',
            }
        ];

        for (let i = 0; i < matchDaysLength; i++) {
            columns.push({
                label: i + 1,
                field: 'round-' + i,
            });
        }

        columns.push({
            label: 'Arv',
            field: 'playedRounds',
        });

        return {
            columns: columns,
            rows: rowData
        };
    }

    setColor(value) {
        if (Number(value) >= 600) {
            return "purple";
        } else if (Number(value) >= 550) {
            return "red";
        } else if (Number(value) >= 500) {
            return "green";
        }
        return "";
    }

    getPlayerStatisticsDetailData() {
        let rowData = [];

        this.props.playerStatistics.map((row) =>
            rowData.push({
                name: row.name,
                club: row.clubName,
                gamePinsTotalAverage: row.gamePinsTotalAverage !== -1 ? round(row.gamePinsTotalAverage, 1) : '',
                gamePinsClassicAverage: round(row.gamePinsClassicAverage, 1),
                gamePinsSystemAverage: round(row.gamePinsSystemAverage, 1),
                gameMissedAverage: round(row.gameMissedAverage, 1),
                setAverage1: round(row.setAverages[0], 1),
                setAverage2: round(row.setAverages[1], 1),
                setAverage3: round(row.setAverages[2], 1),
                setAverage4: round(row.setAverages[3], 1),
                bestGameTotalPins: row.bestGameTotalPins,
                leastGameTotalPins: row.leastGameTotalPins,
                bestSetPins: row.bestSetPins,
                leastSetPins: row.leastSetPins,
                playedRounds: row.playedRounds
            })
        );

        if (!rowData.length) {
            rowData = [{name: '',}]
        }

        let columns = [
            {
                label: 'Nimi',
                field: 'name',
            }, {
                label: 'Klubi',
                field: 'club',
            }, {
                label: 'Keskmine tulemus',
                field: 'gamePinsTotalAverage'
            }, {
                label: 'Klassika keskmine',
                field: 'gamePinsClassicAverage',
            }, {
                label: 'Süsteemi keskmine',
                field: 'gamePinsSystemAverage',
            }, {
                label: 'Keskm. mööda',
                field: 'gameMissedAverage',
            }, {
                label: '1. seeria keskmine',
                field: 'setAverage1',
            }, {
                label: '2. seeria keskmine',
                field: 'setAverage2',
            }, {
                label: '3. seeria keskmine',
                field: 'setAverage3',
            }, {
                label: '4. seeria keskmine',
                field: 'setAverage4',
            }, {
                label: 'Parim tulemus',
                field: 'bestGameTotalPins',
            }, {
                label: 'Väikseim tulemus',
                field: 'leastGameTotalPins',
            }, {
                label: 'Suurim seeria',
                field: 'bestSetPins',
            }, {
                label: 'Väikseim seeria',
                field: 'leastSetPins',
            }, {
                label: 'Arv',
                field: 'playedRounds',
            }
        ];

        return {
            columns: columns,
            rows: rowData.sort((a, b) => a.gamePinsTotalAverage < b.gamePinsTotalAverage ? 1 : -1)
        };
    }

    getTeamStatisticsData() {
        let rowData = [];

        this.props.teamStatistics.map((matchDay) => {

                if (!matchDay.teamStatistics.length) {
                    return null;
                }
                if (matchDay.teamStatistics.length) {
                    for (let i = 0; i < matchDay.teamStatistics.length; i++) {

                        let row = matchDay.teamStatistics[i];
                        rowData.push({
                            round: matchDay.roundNo,
                            name: row.teamName,
                            totalPins: row.totalPins,
                            bestGamePins: row.bestGamePins,
                            averageGamePins: round(row.averageGamePins, 1),
                            classicAverage: round(row.classicAverage, 1),
                            systemAverage: round(row.systemAverage, 1),
                            missAverage: round(row.missAverage, 1),
                            setAverage1: round(row.setAverages[0], 1),
                            setAverage2: round(row.setAverages[1], 1),
                            setAverage3: round(row.setAverages[2], 1),
                            setAverage4: round(row.setAverages[3], 1),
                            setPoints: round(row.setPoints, 1)
                        });

                    }
                }

                return rowData;
            }
        );

        if (!rowData.length) {
            rowData = [{name: '',}]
        }

        let columns = [
            {
                label: 'Voor',
                field: 'round',
            }, {
                label: 'Võistkonna nimi',
                field: 'name',
            }, {
                label: 'Summa kokku',
                field: 'totalPins',
            }, {
                label: 'Parim tulemus',
                field: 'bestGamePins'
            }, {
                label: 'Keskmine tulemus',
                field: 'averageGamePins',
            }, {
                label: 'Klassika keskmine',
                field: 'classicAverage',
            }, {
                label: 'Süsteemi keskmine',
                field: 'systemAverage',
            }, {
                label: 'Keskmiselt mööda',
                field: 'missAverage',
            }, {
                label: '1. seeria keskmine',
                field: 'setAverage1',
            }, {
                label: '2. seeria keskmine',
                field: 'setAverage2',
            }, {
                label: '3. seeria keskmine',
                field: 'setAverage3',
            }, {
                label: '4. seeria keskmine',
                field: 'setAverage4',
            }, {
                label: 'Setipunktid',
                field: 'setPoints',
            }
        ];

        return {
            columns: columns,
            rows: rowData.sort((a, b) => this.sortTeamsData(a, b))
        };
    }

    sortTeamsData(a, b) {
        if (a.round === b.round) {
            return b.totalPins - a.totalPins;
        } else {
            return a.round - b.round;
        }
    }

    getTopPlayersData() {
        let rowData = [];
        this.props.topPlayers.topPlayers.map((row) =>
            rowData.push({
                name: row.name,
                score: row.score,
                team: row.team
            })
        );

        let totalScore = this.props.topPlayers.topPlayers.reduce((a, b) => a + (b['score'] || 0), 0);

        rowData.push({
            name: 'Kokku',
            score: totalScore,
            team: ''
        });

        if (!rowData.length) {
            rowData = [{name: '',}]
        }

        return {
            columns: [
                {
                    label: 'Nimi',
                    field: 'name',
                }, {
                    label: 'Tulemus',
                    field: 'score',
                }, {
                    label: 'Võistkond',
                    field: 'team'
                }
            ],
            rows: rowData
        };
    }

    render() {
        if (!this.props.players && this.props.teams && this.props.teams.length) {
            return this.buildDataTable(this.getTeamsData(), `teamsDataTable ${this.getTableRowClass()}`);
        } else if (this.props.matches) {
            return this.buildDataTable(this.getMatchesData(), "matchesDataTable");
        } else if (this.props.matchDayMatches) {
            return this.buildDataTable(this.getMatchDayMatchesData(), "matchDayMatchesDataTable");
        } else if (this.props.allMatches) {
            return this.buildDataTable(this.getAllMatchesData(), "allMatchesDataTable");
        } else if (this.props.futureMatches && this.props.leagueId) {
            return this.buildDataTable(this.get5UpcomingMatchesBasedOnSelectedLeague(), "futureMatchesLimitedDataTable");
        } else if (this.props.futureMatches) {
            return this.buildDataTable(this.getFutureConfirmedMatchesData(), "futureMatchesDataTable");
        } else if (this.props.seasons) {
            return this.buildDataTable(this.getSeasonsData(), "seasonsDataTable");
        } else if (this.props.actionableTeams) {
            return this.buildDataTable(this.getActionableTeamData(), "actionableTeamDataTable");
        } else if (this.props.leagues) {
            return this.buildDataTable(this.getLeaguesData(), "leaguesDataTable");
        } else if (this.props.users) {
            return this.buildDataTable(this.getUsersData(), "usersDataTable");
        } else if (this.props.clubs) {
            return this.buildDataTable(this.getClubsData(), "clubsDataTable");
        } else if (this.props.clubMembers) {
            return this.buildDataTable(this.getClubMembersData(), "clubMembersDataTable");
        } else if (this.props.playerStatistics && this.props.detailView) {
            return this.buildDataTable(this.getPlayerStatisticsDetailData(), "playerStatisticsDetailDataTable");
        } else if (this.props.playerStatistics) {
            return this.buildDataTable(this.getPlayerStatisticsData(), "playerStatisticsDataTable");
        } else if (this.props.teamStatistics) {
            return this.buildDataTable(this.getTeamStatisticsData(), "teamStatisticsDataTable");
        } else if (this.props.topPlayers) {
            return this.buildDataTable(this.getTopPlayersData(), "topPlayersDataTable");
        }
        return null;
    }

    buildDataTable(tableData, className) {
        return (
            <div>
                {tableData ?
                    <MDBDataTable
                        className={className}
                        responsive
                        small
                        striped
                        hover
                        noBottomColumns
                        data={tableData}
                        paginationLabel={["<", ">"]}
                        noRecordsFoundLabel={'Tulemusi ei leitud'}
                        infoLabel={['Näitan', '-', 'kokku', 'kirjest']}
                        entriesLabel={'Näita korraga'}
                        entriesOptions={[10, 25, 50, 100, 200]}
                        entries={this.props.entries ? this.props.entries : 10}
                        searchLabel={'Otsi'}
                        bordered={false}
                        paging={!!this.props.paging}
                        searching={!!this.props.searching}
                        sortable={!!this.props.sortable}
                        order={!!this.props.order ? this.props.order : []}
                    />
                    :
                    <div style={{marginTop: '25px'}}>
                        Info puudub
                    </div>
                }
            </div>
        );
    }

    getTableRowClass() {

        let secondRoundSecondGroupPositions = [];
        this.props.teams.map((row) => {
                if (row.seasonScore && row.seasonScore.secondRoundGroup === 2) {
                    secondRoundSecondGroupPositions.push(row.seasonScore.position);
                }
            }
        );

        let tableRowColorClass = "";

        if (secondRoundSecondGroupPositions.length > 0) {
            tableRowColorClass += "teamsDataTable-divide-second-round-groups ";
        }

        if (this.props.currentLeagueName === "Meistriliiga (mehed)") {
            tableRowColorClass += "teamsDataTable-men-master-league";
        } else if (this.props.currentLeagueName === "Esiliiga (mehed)") {
            tableRowColorClass += "teamsDataTable-men-premier-league";
        }
        return tableRowColorClass;
    }
}

export default withRouter(Datatable);
