import axios from 'axios';
import {getToken, isUserLoggedIn, logOut} from "./components/user/User";

export const API = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

API.interceptors.request.use(function (config) {
    if (isUserLoggedIn()) {
        config.headers.Authorization = 'Bearer ' + getToken();
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

API.interceptors.response.use(function (response) {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        logOut();
        window.location.href = "/";
    }
    return Promise.reject(error);
});

