import React from 'react';
import {MDBCollapse, MDBLink, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem, MDBNavLink} from 'mdbreact';
import {getUsername, isUserLoggedIn, logOut} from "../components/user/User";
import {withRouter} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import '../style/Header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false
        };
    }

    onClick = () => {
        this.setState({
            collapse: !this.state.collapse
        });
    };

    closeNavBarMenu = () => {
        this.setState({
            collapse: false
        });
    }

    handleLogout(e) {
        e.preventDefault();
        logOut();
        this.props.history.push("/");
    }

    render() {
        return (
            <div style={{marginBottom: '90px'}}>
                <header>
                    <MDBNavbar id="header-navbar" color="blue" dark expand="md" fixed="top">
                        <MDBNavbarBrand href="/" onClick={() => this.closeNavBarMenu()}>
                            <img src="logo192.png" height="30" alt="logo"/>
                        </MDBNavbarBrand>
                        {!this.state.isWideEnough && <MDBNavbarToggler onClick={() => this.onClick()}/>}
                        <MDBCollapse isOpen={this.state.collapse} navbar onClick={() => this.closeNavBarMenu()}>
                            <MDBNavbarNav left>
                                <MDBNavItem>
                                    <MDBNavLink to="/">
                                        Hooajad
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/clubs">
                                        Klubid
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/future_matches">
                                        Eelolevad mängud
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/statistics">
                                        Statistika
                                    </MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                            <MDBNavbarNav right>
                                {isUserLoggedIn()
                                    ? (<>
                                            <MDBNavItem className={"justify-content-center align-items-center d-flex mr-3"}>
                                                <FontAwesomeIcon className="mr-1" icon={faUser}/> {getUsername()}
                                            </MDBNavItem>
                                            <MDBNavItem>
                                                <MDBNavLink to="/admin">
                                                    Administreerimine
                                                </MDBNavLink>
                                            </MDBNavItem>
                                            <MDBNavItem>
                                                <MDBLink to="#" onClick={e => this.handleLogout(e)}>Logi välja</MDBLink>
                                            </MDBNavItem>
                                        </>
                                    )
                                    : <MDBNavItem>
                                        <MDBNavLink to="/login">
                                            Logi sisse
                                        </MDBNavLink>
                                    </MDBNavItem>
                                }
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                </header>
                {this.state.collapse
                    ? <div id="full-page-invisible-layer" onClick={() => this.closeNavBarMenu()}/>
                    : null
                }
            </div>
        );
    }
}

export default withRouter(Header);
