import {Route} from "react-router";
import {Link} from "react-router-dom";
import React from "react";
import {Breadcrumb} from "react-bootstrap";

export const Breadcrumbs = (props) => (
    <Breadcrumb>
        <Route path='/' render={(props) => <BreadcrumbsItem {...props}/>}/>
        <Route path='/:path' component={BreadcrumbsItem}/>
    </Breadcrumb>
)

const BreadcrumbsItem = ({match, ...rest}) => (
    <>
        <Breadcrumb.Item active>
            <Link to={match.url || ''} className={match.isExact ? 'disabled-link' : ''}>
                {mapUrlToName(match.url)}
            </Link>
        </Breadcrumb.Item>
        <Route path={`${match.url}/:path`} component={BreadcrumbsItem}/>
    </>
);

const mapUrlToName = (url) => {
    switch (url) {
        case "/":
            return "Avaleht";
        case "/admin":
            return "Administreerimine";
        case "/admin/match_details":
        case "/match_details":
            return "Matši detailid";
        case "/admin/add_match":
            return "Matši lisamine";
        case "/admin/edit_match":
            return "Matši muutmine";
        case "/team_details":
            return "Võistkonna detailid";
        case "/clubs":
            return "Klubid";
        case "/future_matches":
            return "Eelolevad mängud";
        default:
            return url;
    }
}