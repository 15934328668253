import React, {Component} from "react";
import {API} from "../../api";
import {convertDateTimeToIsoString, createDateFromBackendDate, createDateTimeFromBackendDate} from "../../util/dateUtil";
import DatePicker from "react-datepicker/es";
import {et} from "date-fns/esm/locale";
import {Button} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Badge from "react-bootstrap/Badge";


class DateTimeOverlayPopover extends Component {
    DATEPICKER_WIDTH = "350px";
    DATEPICKER_TIME_INTERVAL_IN_MINUTES = 15;
    state = {
        selectedDateTime: null
    };

    componentDidMount() {
        const {season, selectedDateTime} = this.props;
        this.setState({selectedDateTime: this.setSelectedOrDefault(selectedDateTime, season)});
    }

    setSelectedOrDefault(selectedDateTime, season) {
        return selectedDateTime ? createDateTimeFromBackendDate(selectedDateTime) : createDateFromBackendDate(season.startDate);
    }

    saveMatchDateTime() {
        const {matchId, forceLeagueCalendarUpdate} = this.props;
        const {selectedDateTime} = this.state;
        this.updateMatchDateTime(matchId, selectedDateTime ? convertDateTimeToIsoString(selectedDateTime) : null).then(() => {
            forceLeagueCalendarUpdate();
            this.closePopover();
        });
    }

    closePopover() {
        document.body.click();
    }

    updateMatchDateTime(matchId, newMatchDateTime) {
        return API.patch(`/match/${matchId}/matchDateTime`, {matchDateTime: newMatchDateTime});
    }

    render() {
        const {season, selectedDateTime} = this.props;
        return (
            <OverlayTrigger trigger="click" placement="bottom" overlay={this.renderPopOver()} rootClose={true}>
                <Badge
                    style={{cursor: 'pointer'}}
                    variant="primary"
                    onClick={() => this.setState({selectedDateTime: this.setSelectedOrDefault(selectedDateTime, season)})}
                >
                    Muuda aega
                </Badge>
            </OverlayTrigger>
        );
    }

    renderPopOver() {
        const {season} = this.props;
        return (
            <Popover id="datetime-popover" style={{minWidth: this.DATEPICKER_WIDTH}}>
                <Popover.Title as="h3">Muuda mängu aega</Popover.Title>
                <Popover.Content>
                    <DatePicker
                        selected={this.state.selectedDateTime}
                        locale={et}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={this.DATEPICKER_TIME_INTERVAL_IN_MINUTES}
                        minDate={createDateFromBackendDate(season.startDate)}
                        maxDate={createDateFromBackendDate(season.endDate)}
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText="Kuupäev ja kellaaeg"
                        onChange={datetime => this.setState({selectedDateTime: datetime})}
                    />
                    <Button
                        disabled={!this.state.selectedDateTime}
                        onClick={() => this.saveMatchDateTime()}
                    >
                        Salvesta
                    </Button>
                </Popover.Content>
            </Popover>
        );
    }

}

export default DateTimeOverlayPopover;