import React from "react";
import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import "../style/Footer.css";

const Footer = () => {
  const renderTabletAndDesktopContent = () =>
    <div className="tablet-desktop-content">
      <MDBRow>
        <MDBCol md="6">
          <ul>
            <li className="list-unstyled text-left">
              <h6 className="title text-left"><strong>Liigamängude ametlik infoallikas</strong></h6>
              Klubide Eesti meistrivõistlused sportkeeglis
            </li>
            <li className="list-unstyled text-left">
              <u><a href="https://arhiiv.evml.ee/2020/klubid/klubid1920.htm">Varasemate hooaegade arhiiv</a></u>
            </li>
          </ul>
        </MDBCol>
        <MDBCol md="6">
          <ul>
            <li className="list-unstyled text-left">
              <h6 className="title text-left"><strong>Kasulik</strong></h6>
              <u><a href="https://keegel.evml.ee/dokumendid/09_V6istlusjuhend_-_Klubidele.pdf"
                    >Liigamängude juhend</a></u>
            </li>
            <li className="list-unstyled text-left">
              <u><a href="https://keegel.evml.ee/dokumendid/02_EVML_v6istluste_reglement.pdf"
                    >Võistluste
                reglement</a></u>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>
    </div>;

  const renderMobileContent = () =>
    <div className="mobile-content">
      <MDBRow>
        <MDBCol md="6" className="text-center mb-2">
          <h6><strong>Liigamängude ametlik infoallikas</strong></h6>
          Klubide Eesti meistrivõistlused sportkeeglis
          <br/>
          <u><a href="https://arhiiv.evml.ee/2020/klubid/klubid1920.htm">Varasemate hooaegade arhiiv</a></u>
        </MDBCol>
      </MDBRow>
    </div>;

  return (
    <MDBFooter color="blue" className="font-small pt-3 mt-3">
      <MDBContainer fluid className="text-center text-md-left">
        {renderTabletAndDesktopContent()}
        {renderMobileContent()}
      </MDBContainer>
      <MDBContainer fluid className="footer-copyright text-center py-1">
        &copy; {new Date().getFullYear()} Copyright: Eesti Veeremängude Liit
      </MDBContainer>
    </MDBFooter>
  );
};

export default Footer;
