import React, {Component} from "react";
import '../style/SetMatchDetail.css';
import SetMatchDetailInput from "../modules/SetMatchDetailInput";
import {API} from "../api";
import {getUrlVars, hasNumberDuplicates, renderSpinner} from "../util/util";
import DatePicker from "react-datepicker/es";
import {et} from "date-fns/esm/locale";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {getTeamId, isUserAdmin, isUserLoggedIn} from "./user/User";
import {withRouter} from "react-router";
import {
    convertDateTimeToIsoString,
    createDateFromBackendDate,
    createDateTimeFromBackendDate,
    getCurrentDateMinusDays, getCurrentDateWithZeroTime
} from "../util/dateUtil";
import {Alert} from "react-bootstrap";
import SubstitutePlayerInput from "../modules/SubstitutePlayerInput";
import Form from "react-bootstrap/Form";


class AddMatchResult extends Component {
    state = {
        gameDetailRequests: [],
        locationId: "",
        locations: [],
        matchDateTime: "",
        matchId: getUrlVars()["match_id"],
        homePlayers: [],
        awayPlayers: [],
        movedHomePlayers: [],
        movedAwayPlayers: [],
        homeTeamId: null,
        homeTeamName: "",
        awayTeamId: null,
        awayTeamName: "",
        isMatchAnnulled: null,
        loadingHomeTeam: true,
        loadingAwayTeam: true,
        isSaving: false,
        numberOfGames: undefined,
        errorMessage: "",
        substitutionRequests: [],
        movedHomeSubstitutions: [],
        movedAwaySubstitutions: [],
        season: null
    };
    GAME_SETS = 4;
    DATEPICKER_TIME_INTERVAL_IN_MINUTES = 15;

    componentDidMount() {
        this.loadMatch().then(matchRes => {
            Promise.all([this.loadLocations(), this.loadSeasonDetails(matchRes.data.seasonId), this.loadHomeTeam(), this.loadAwayTeam()]).then(() => {
                if (this.props.mode === "NEW") {
                    this.setState({gameDetailRequests: this.setNewGameDetailFields()});
                    this.setState({substitutionRequests: this.setNewSubstitutionFields()});
                } else {
                    this.setupExistingMatchResult(matchRes);
                }
            })
        })
    }

    loadLocations() {
        API.get('/location/locations').then(res => {
            this.setState({locations: res.data});
        });
    }

    loadMatch() {
        return API.get(`/match/${this.state.matchId}`).then(res => {
            this.setState({homeTeamId: res.data.homeTeamId});
            this.setState({awayTeamId: res.data.awayTeamId});
            return res;
        })
    }

    loadHomeTeam() {
        return API.get(`/team/${this.state.homeTeamId}`).then(homeTeamRes => {
            this.setState({
                homePlayers: homeTeamRes.data.players,
                numberOfGames: !!homeTeamRes.data.isAllFemaleTeam ? 4 : 6,
                homeTeamName: homeTeamRes.data.name,
                loadingHomeTeam: false
            })
        });
    }

    loadAwayTeam() {
        return API.get(`/team/${this.state.awayTeamId}`).then(awayTeamRes => {
            this.setState({
                awayPlayers: awayTeamRes.data.players,
                awayTeamName: awayTeamRes.data.name,
                loadingAwayTeam: false
            })
        });
    }

    loadSeasonDetails(seasonId) {
        return API.get(`/season/${seasonId}`).then(res => {
            this.setState({season: res.data});
        });
    }

    setupExistingMatchResult(res) {
        this.findPlayersThatHaveMovedFromTeam(res.data);
        this.setState({gameDetailRequests: this.setExistingGameDetailFields(res.data)});
        this.setState({matchDateTime: createDateTimeFromBackendDate(res.data.matchDateTime)});
        this.setState({isMatchAnnulled: res.data.isMatchAnnulled});
        this.setState({locationId: this.findLocationIdByName(res.data.location).id});
        if (res.data.substitutions) {
            this.findSubstitutionsThatHaveMovedFromTeam(res.data);
            this.setState({substitutionRequests: this.setExistingSubstitutionFields(res.data)});
        } else {
            this.setState({substitutionRequests: this.setNewSubstitutionFields()});
        }
    }

    findLocationIdByName = (name) => {
        return this.state.locations.find((location) => {
            return location.name === name;
        })
    }

    setExistingSubstitutionFields = (data) => {
        const substitutionRequests = [];
        const homeSubstitutions = data.substitutions.filter(sub => sub.teamId === data.homeTeamId);
        const awaySubstitutions = data.substitutions.filter(sub => sub.teamId === data.awayTeamId);
        homeSubstitutions.forEach(sub => {
            substitutionRequests.push({
                substitutionGameNo: sub.substitutionGameNo,
                teamId: sub.teamId,
                substitutionEntered: sub.substitutionEntered,
                substitutionPlayer: sub.substitutionPlayer,
                oldPlayer: sub.substitutionPlayer
            });
        })
        this.fillWithEmptySubstitutionsUntil(2, substitutionRequests);
        awaySubstitutions.forEach(sub => {
            substitutionRequests.push({
                substitutionGameNo: sub.substitutionGameNo,
                teamId: sub.teamId,
                substitutionEntered: sub.substitutionEntered,
                substitutionPlayer: sub.substitutionPlayer,
                oldPlayer: sub.substitutionPlayer
            });
        })
        this.fillWithEmptySubstitutionsUntil(4, substitutionRequests);
        return substitutionRequests;
    }

    fillWithEmptySubstitutionsUntil(number, substitutionRequests) {
        while (substitutionRequests.length < number) {
            substitutionRequests.push({
                substitutionGameNo: "",
                teamId: "",
                substitutionEntered: "",
                substitutionPlayer: ""
            });
        }
    }

    setNewSubstitutionFields = () => {
        const substitutionRequests = [];
        for (let i = 0; i < 4; i++) {
            substitutionRequests.push({
                substitutionGameNo: "",
                teamId: "",
                substitutionEntered: "",
                substitutionPlayer: ""
            });
        }
        return substitutionRequests;
    }

    setExistingGameDetailFields = (data) => {
        const gameDetailRequests = [];
        for (let game = 0; game < this.state.numberOfGames; game++) {
            const setDetailRequests = [];
            for (let set = 0; set < this.GAME_SETS; set++) {
                setDetailRequests.push({
                    awayPlayerId: data.matchDetails[game].awayPlayer.playerId,
                    awaySetClassicPins: data.matchDetails[game].awayPlayer.sets[set].classic,
                    awaySetMiss: data.matchDetails[game].awayPlayer.sets[set].miss,
                    awaySetTotalPins: data.matchDetails[game].awayPlayer.sets[set].total,
                    awaySubstitutions: [],
                    homePlayerId: data.matchDetails[game].homePlayer.playerId,
                    homeSetClassicPins: data.matchDetails[game].homePlayer.sets[set].classic,
                    homeSetMiss: data.matchDetails[game].homePlayer.sets[set].miss,
                    homeSetTotalPins: data.matchDetails[game].homePlayer.sets[set].total,
                    homeSubstitutions: [],
                    setNo: set + 1
                });
            }
            gameDetailRequests.push({gameNo: game + 1, setDetailRequests: setDetailRequests});
        }
        return gameDetailRequests;
    }

    findPlayersThatHaveMovedFromTeam = (data) => {
        const movedHomePlayers = [];
        const movedAwayPlayers = [];
        for (let game = 0; game < this.state.numberOfGames; game++) {
            const homePlayerInTeam = this.state.homePlayers.find(player => player.id === data.matchDetails[game].homePlayer.playerId)
            const awayPlayerInTeam = this.state.awayPlayers.find(player => player.id === data.matchDetails[game].awayPlayer.playerId)
            if (!homePlayerInTeam) {
                movedHomePlayers.push(data.matchDetails[game].homePlayer);
            }
            if (!awayPlayerInTeam) {
                movedAwayPlayers.push(data.matchDetails[game].awayPlayer);
            }
        }
        this.setState({movedHomePlayers: movedHomePlayers})
        this.setState({movedAwayPlayers: movedAwayPlayers})
    }


    findSubstitutionsThatHaveMovedFromTeam = (data) => {
        const homePlayerIds = this.state.homePlayers.map(player => player.id)
        const awayPlayerIds = this.state.awayPlayers.map(player => player.id)
        const movedHomeSubstitutions = data.substitutions.filter(sub => sub.teamId === this.state.homeTeamId && !homePlayerIds.includes(sub.substitutionPlayer));
        const movedAwaySubstitutions = data.substitutions.filter(sub => sub.teamId === this.state.awayTeamId && !awayPlayerIds.includes(sub.substitutionPlayer));
        this.setState({movedHomeSubstitutions: movedHomeSubstitutions})
        this.setState({movedAwaySubstitutions: movedAwaySubstitutions})
    }

    setNewGameDetailFields = () => {
        const gameDetails = [];
        for (let game = 0; game < this.state.numberOfGames; game++) {
            const setDetails = [];
            for (let set = 0; set < this.GAME_SETS; set++) {
                setDetails.push({
                    awayPlayerId: "",
                    awaySetClassicPins: "",
                    awaySetMiss: "",
                    awaySetTotalPins: "",
                    awaySubstitutions: [],
                    homePlayerId: "",
                    homeSetClassicPins: "",
                    homeSetMiss: "",
                    homeSetTotalPins: "",
                    homeSubstitutions: [],
                    setNo: set + 1
                });
            }
            gameDetails.push({gameNo: game + 1, setDetailRequests: setDetails});
        }
        return gameDetails;
    }

    handleChange = (e) => {
        if (["awayPlayerId", "awaySetClassicPins", "awaySetMiss", "awaySetTotalPins",
            "awaySubstitutions", "homePlayerId", "homeSetClassicPins", "homeSetMiss",
            "homeSetTotalPins", "homeSubstitutions", "setNo"].includes(e.target.className)) {
            let gameDetailRequests = [...this.state.gameDetailRequests];
            let setDetailRequests = gameDetailRequests[e.target.dataset.gameno].setDetailRequests;
            setDetailRequests[e.target.dataset.id][e.target.className] = e.target.value.toUpperCase();
            this.setState({gameDetailRequests});
        } else if (["substitutionPlayer", "substitutionEntered", "substitutionGameNo"].includes(e.target.className)) {
            let substitutionRequests = [...this.state.substitutionRequests];
            substitutionRequests[e.target.dataset.id][e.target.className] = e.target.value.toUpperCase();
            substitutionRequests[e.target.dataset.id]["teamId"] = e.target.dataset.teamid;
            this.setState({substitutionRequests});
        } else {
            this.setState({[e.target.name]: e.target.value.toUpperCase()})
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({errorMessage: ""});
        if (!this.state.matchDateTime) {
            this.setState({errorMessage: "Mängu toimumise aeg on kohustuslik väli"});
            return;
        }
        if (!this.state.locationId) {
            this.setState({errorMessage: "Mängu toimumise koht on kohustuslik väli"});
            return;
        }
        for (const game of this.state.gameDetailRequests) {
            for (const set of game.setDetailRequests) {
                if (set.homePlayerId === "" || set.homeSetClassicPins === "" || set.homeSetMiss === "" || set.homeSetTotalPins === ""
                    || set.awayPlayerId === "" || set.awaySetClassicPins === "" || set.awaySetMiss === "" || set.awaySetTotalPins === "") {
                    this.setState({errorMessage: "Kõik väljad ei ole täidetud."});
                    return;
                }
                if (Number(set.homeSetClassicPins) > Number(set.homeSetTotalPins) || Number(set.awaySetClassicPins) > Number(set.awaySetTotalPins)) {
                    this.setState({errorMessage: "Setis ei saa olla klassika punkte rohkem kui kokku punkte."});
                    return;
                }
            }
        }
        for (const sub of this.state.substitutionRequests) {
            if ((sub.substitutionPlayer !== "" && (sub.substitutionGameNo === "" || sub.substitutionEntered === ""))
                || (sub.substitutionPlayer === "" && (sub.substitutionGameNo !== "" || sub.substitutionEntered !== ""))) {
                this.setState({errorMessage: `Vahetusmängija väljad ei ole täielikult täidetud.`});
                return;
            }
        }
        if (hasNumberDuplicates(this.findSelectedPlayersAndSubstitutions())) {
            this.setState({errorMessage: "Sama võistleja ei saa osaleda mitmes mängus."})
            return;
        }

        const data = {
            gameDetailRequests: this.state.gameDetailRequests,
            locationId: this.state.locationId,
            matchDateTime: convertDateTimeToIsoString(this.state.matchDateTime),
            isMatchAnnulled: this.state.isMatchAnnulled,
            matchId: this.state.matchId,
            substitutionRequests: this.state.substitutionRequests
        };

        if (this.props.mode === "NEW") {
            this.saveNewMatch(data);
        } else {
            this.editMatch(data);
        }

    };

    saveNewMatch(data) {
        this.setState({isSaving: true});
        API.post('/match/', data)
            .then(() => {
                this.props.history.push("/admin");
            }).catch(reason => {
            if (reason.response && reason.response.data) {
                this.setState({errorMessage: reason.response.data.message});
            }
        }).finally(() => this.setState({isSaving: false}));
    }

    editMatch(data) {
        this.setState({isSaving: true});
        API.patch('/match/', data)
            .then(() => {
                this.props.history.push("/admin");
            }).catch(reason => {
            if (reason.response && reason.response.data) {
                this.setState({errorMessage: reason.response.data.message});
            }
        }).finally(() => this.setState({isSaving: false}));
    }

    findSelectedPlayersAndSubstitutions() {
        const playersAndSubstitutions = [];
        const gameFirstSet = 0;
        this.state.gameDetailRequests.forEach(game => {
            playersAndSubstitutions.push(game.setDetailRequests[gameFirstSet].homePlayerId);
            playersAndSubstitutions.push(game.setDetailRequests[gameFirstSet].awayPlayerId);
        });
        this.state.substitutionRequests.forEach(substitution => {
            if (substitution.substitutionPlayer !== "") {
                playersAndSubstitutions.push(substitution.substitutionPlayer)
            }
        });
        return playersAndSubstitutions;
    }

    setMatchDateTime = (dateTime) => {
        this.setState({matchDateTime: dateTime});
    };

    setLocation = (e) => {
        this.setState({locationId: e.target.value})
    };

    setIsMatchAnnulled = (isChecked) => {
        this.setState({isMatchAnnulled: isChecked})
    };

    render() {
        let {homePlayers, awayPlayers, loadingAwayTeam, loadingHomeTeam, gameDetailRequests, numberOfGames} = this.state;

        if (loadingAwayTeam || loadingHomeTeam || gameDetailRequests.length < numberOfGames) {
            return renderSpinner();
        }

        if ((!homePlayers.length || !awayPlayers.length) && this.props.mode === "NEW") {
            return <div>Võistkonnal puuduvad mängijad ning seega ei ole võimalik mängu lisada</div>
        }

        if (isUserLoggedIn()) {
            return this.renderForm();
        }

        return (<h2>Mängu sisestamiseks peate olema sisse logitud!</h2>);
    }

    renderForm() {
        let {errorMessage, isSaving} = this.state;
        return (
            <form
                onSubmit={this.handleSubmit}
                onChange={this.handleChange}
            >
                {this.renderLocationAndDateInput()}
                {this.renderGames()}
                <div className="match_content mt-3">
                    {isSaving ? renderSpinner() : this.conditionallyRenderSaveMatchButton()}
                </div>
                {errorMessage &&
                <Alert variant="danger" className={"m-3"}>
                    {errorMessage}
                </Alert>
                }
            </form>
        );
    }

    conditionallyRenderSaveMatchButton() {
        if (isUserAdmin() || this.isUserWhoIsInHomeOrAwayTeam()) {
            return <input type="submit" className="btn btn-primary" value="Salvesta matš"/>;
        }
        return null;
    }

    isUserWhoIsInHomeOrAwayTeam() {
        if (this.props.mode === "NEW") {
           return isUserLoggedIn()
            && (getTeamId() === this.state.homeTeamId || getTeamId() === this.state.awayTeamId)
            && getCurrentDateWithZeroTime() <= createDateFromBackendDate(this.state.season.endDate);
        }
        return isUserLoggedIn()
          && (getTeamId() === this.state.homeTeamId || getTeamId() === this.state.awayTeamId)
          && this.state.matchDateTime > getCurrentDateMinusDays(5);
    }

    renderGames() {
        let {
            gameDetailRequests, homePlayers, awayPlayers, homeTeamId, awayTeamId, numberOfGames, movedHomePlayers, movedAwayPlayers,
            movedHomeSubstitutions, movedAwaySubstitutions
        } = this.state;
        let games = [];

        for (let i = 0; i < numberOfGames; i++) {
            games.push(
                <div key={"gameDiv" + i} className="mt-3">
                    <div className="table_wrapper">
                        <div className="add_home_player">
                            <div className="form-group no_bottom_margin">
                                <MDBTable striped className="merged_tables">
                                    {i === 0 && this.renderTableHeader("Koduvõistkond")}
                                </MDBTable>
                            </div>
                        </div>
                        <div className="add_guest_player">
                            <div className="form-group no_bottom_margin">
                                <MDBTable striped className="merged_tables">
                                    {i === 0 && this.renderTableHeader("Külalisvõistkond")}
                                </MDBTable>
                            </div>
                        </div>
                    </div>

                    <div className="table_wrapper">
                        <div className="add_home_player">
                            <SetMatchDetailInput setDetailRequests={gameDetailRequests[i].setDetailRequests}
                                                 players={homePlayers}
                                                 movedPlayers={movedHomePlayers}
                                                 gameIndex={i}
                                                 isHome={true}
                            />
                            {this.renderMatchTotals(i, true, numberOfGames)}
                        </div>
                        <div className="add_guest_player">
                            <SetMatchDetailInput setDetailRequests={gameDetailRequests[i].setDetailRequests}
                                                 players={awayPlayers}
                                                 movedPlayers={movedAwayPlayers}
                                                 gameIndex={i}
                                                 isHome={false}
                            />
                            {this.renderMatchTotals(i, false, numberOfGames)}
                        </div>
                    </div>
                </div>
            );
        }

        games.push(
            <div className="table_wrapper" style={{marginTop: "20px"}} key={"substitutions-input-tables"}>
                <MDBTable>
                    <MDBTableHead color="blue" textWhite>
                        <tr>
                            <th width="20%">Mäng nr.</th>
                            <th width="60%">Vahetusmängija</th>
                            <th width="20%">Viske nr.</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        <SubstitutePlayerInput players={homePlayers}
                                               teamId={homeTeamId}
                                               number="0"
                                               substitutions={this.state.substitutionRequests}
                                               movedSubstitutions={movedHomeSubstitutions}
                        />
                        <SubstitutePlayerInput players={homePlayers}
                                               teamId={homeTeamId}
                                               number="1"
                                               substitutions={this.state.substitutionRequests}
                                               movedSubstitutions={movedHomeSubstitutions}
                        />
                    </MDBTableBody>
                </MDBTable>
                <MDBTable style={{marginLeft: "10px"}}>
                    <MDBTableHead color="blue" textWhite>
                        <tr>
                            <th width="20%">Mäng nr.</th>
                            <th width="60%">Vahetusmängija</th>
                            <th width="20%">Viske nr.</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        <SubstitutePlayerInput players={awayPlayers}
                                               teamId={awayTeamId}
                                               number="2"
                                               substitutions={this.state.substitutionRequests}
                                               movedSubstitutions={movedAwaySubstitutions}
                        />
                        <SubstitutePlayerInput players={awayPlayers}
                                               teamId={awayTeamId}
                                               number="3"
                                               substitutions={this.state.substitutionRequests}
                                               movedSubstitutions={movedAwaySubstitutions}
                        />
                    </MDBTableBody>
                </MDBTable>
            </div>
        );

        return games;
    }

    renderMatchTotals(gameIdx, isHome, numberOfGames) {
        if (gameIdx === numberOfGames - 1) {
            const matchTotals = this.calculateMatchTotals(isHome);
            return (
                <MDBTable striped className="merged_tables">
                    <MDBTableBody>
                        <tr>
                            <td width="120px">Matš kokku</td>
                            <td width="45px">{matchTotals.classicSum}</td>
                            <td width="45px">{matchTotals.missSum}</td>
                            <td width="45px">{matchTotals.totalSum}</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            );
        }
    }

    calculateMatchTotals(isHome) {
        let classicSum = 0;
        let missSum = 0;
        let totalSum = 0;
        this.state.gameDetailRequests.forEach(game => {
            game.setDetailRequests.forEach(set => {
                classicSum += Number(isHome ? set.homeSetClassicPins : set.awaySetClassicPins);
                missSum += Number(isHome ? set.homeSetMiss : set.awaySetMiss);
                totalSum += Number(isHome ? set.homeSetTotalPins : set.awaySetTotalPins);
            })
        });
        return {classicSum, missSum, totalSum};
    }

    renderTableHeader(teamLabel) {
        return <MDBTableHead color="blue" textWhite>
            <tr>
                <th width="120px">{teamLabel}</th>
                <th width="45px">Klassika</th>
                <th width="45px">Mööda</th>
                <th width="45px">Kokku</th>
            </tr>
        </MDBTableHead>;
    }

    renderLocationAndDateInput() {
        let {locations, locationId, isMatchAnnulled, matchDateTime, homeTeamName, awayTeamName, season} = this.state;
        locations.sort((a, b) => a.name.localeCompare(b.name, 'et'));
        return <div className="d-flex align-items-center justify-content-between table_wrapper">
            <h2 className="mr-3 add_home_player">{homeTeamName} - {awayTeamName}</h2>
            <MDBTable striped className="merged_tables add_guest_player">
                <MDBTableHead color="blue" textWhite>
                    <tr>
                        <th>Asukoht</th>
                        <th>Aeg</th>
                        <th>Matš annulleeritud</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    <tr>
                        <td width="130px">
                            <select
                                value={locationId}
                                onChange={e => this.setLocation(e)}
                            >
                                <option value=""/>
                                {locations.map((location) =>
                                    <option key={"location" + location.id} value={location.id}>
                                        {location.name}
                                    </option>
                                )}
                            </select>
                        </td>
                        <td><DatePicker
                            locale={et}
                            selected={matchDateTime}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={this.DATEPICKER_TIME_INTERVAL_IN_MINUTES}
                            minDate={createDateFromBackendDate(season.startDate)}
                            maxDate={createDateFromBackendDate(season.endDate)}
                            dateFormat="dd.MM.yyyy HH:mm"
                            onChange={date => this.setMatchDateTime(date)}
                        /></td>
                        <td>
                            <Form.Check
                                custom
                                type={`checkbox`}
                                id={`match-annulled-checkbox`}
                                label={``}
                                defaultChecked={isMatchAnnulled}
                                onClick={event => this.setIsMatchAnnulled(event.target.checked)}
                            />
                        </td>
                    </tr>
                </MDBTableBody>
            </MDBTable>
        </div>;
    }
}

export default withRouter(AddMatchResult);
