import React, {Component} from "react";
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import '../../style/Popup.css';
import '../../style/Print.css';
import {API} from "../../api";
import {formatBackendDateEtLocale, formatBackendDateTimeEtLocale} from "../../util/dateUtil";
import DateTimeOverlayPopover from "./DateTimeOverlayPopover";
import {isUserLoggedIn} from "../../components/user/User";
import Form from 'react-bootstrap/Form'
import {renderSpinner} from "../../util/util";
import Col from "react-bootstrap/Col";
import ReactToPrint from 'react-to-print';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

class CalendarPopup extends Component {
    render() {
        return (
            <>
                <div className="print-button-wrapper">
                    <ReactToPrint
                        trigger={() => <Button id="print-button">Prindi</Button>}
                        content={() => this.componentRef}
                    />
                </div>
                <CalendarPrintWrapper
                    leagueId={this.props.leagueId}
                    loggedInUserTeamId={this.props.loggedInUserTeamId}
                    closeTrigger={this.props.closeTrigger}
                    ref={el => (this.componentRef = el)}
                />
            </>

        )
    }

}

export default CalendarPopup;

class CalendarPrintWrapper extends Component {
    state = {
        calenderData: null,
        season: null,
        isLoadingLeagueCalendar: true,
        isLoadingSeason: true
    };

    componentDidMount() {
        this.loadLeagueCalendar().then((res) => {
            if (res.data) {
                this.loadSeason(res.data.seasonId);
            } else {
                this.setState({isLoadingSeason: false});
            }
        })
    }

    loadLeagueCalendar = () => {
        return API.get(`/calendar/league/${this.props.leagueId}`).then(res => {
            this.setState({calenderData: res.data});
            return res;
        }).finally(() => this.setState({isLoadingLeagueCalendar: false}))
    }

    loadSeason(seasonId) {
        API.get(`/season/${seasonId}`).then(res => {
            this.setState({season: res.data});
        }).finally(() => this.setState({isLoadingSeason: false}))
    }

    render() {
        if (this.state.isLoadingLeagueCalendar || this.state.isLoadingSeason) {
            return renderSpinner();
        }
        if (!this.state.calenderData || !this.state.calenderData.matchDays || !this.state.season) {
            return null;
        }
        const roundSize = Math.floor(this.findDistinctTeams().size / 2);
        return (
            <div className="home_player">
                <Row>
                    <Col className="text-right">
                        <button className="close-modal" onClick={this.props.closeTrigger}>&times;</button>
                    </Col>
                </Row>

                <h3 className="to-print text-center">{this.state.season.name}</h3>
                <h3 className="to-print text-center">{this.state.calenderData.leagueName}</h3>
                <MDBTable striped small className="content">
                    <MDBTableHead color="blue" textWhite className="print-style">{this.renderHeaderRow()}</MDBTableHead>
                    <MDBTableBody>
                        {this.state.calenderData.matchDays.map((matchDay, idx) =>
                            <React.Fragment key={`match-day-fragment-${idx}`}>
                                {this.conditionallyRenderRoundRow(idx, roundSize)}
                                {this.renderMatchRow(idx, matchDay)}
                            </React.Fragment>
                        )}
                    </MDBTableBody>
                </MDBTable>
            </div>
        );
    }

    findDistinctTeams() {
        return new Set(this.state.calenderData.matchDays.map(x => x.homeTeamId));
    }

    renderHeaderRow() {
        return (
            <tr>
                <th>Algusaeg</th>
                <th>Lõppaeg</th>
                <th>Kodumeeskond</th>
                <th>Külalismeeskond</th>
                <th>Toimumise aeg</th>
                {isUserLoggedIn() && <th>Aeg kinnitatud</th>}
                {isUserLoggedIn() && <th/>}
            </tr>
        );
    }

    conditionallyRenderRoundRow(idx, roundSize) {
        if (idx % roundSize === 0) {
            return (
                <tr>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td className="font-weight-bold print-style">Voor {(idx + roundSize) / roundSize}</td>
                    {isUserLoggedIn() && <td className="not-to-print"/>}
                    {isUserLoggedIn() && <td className="not-to-print"/>}
                </tr>
            )
        }
    }

    renderMatchRow(idx, matchDay) {
        return (
            <tr key={idx}>
                <td className="print-style">{this.formatDate(matchDay.matchDayStartDate)}</td>
                <td className="print-style">{this.formatDate(matchDay.matchDayEndDate)}</td>
                <td className="print-style">{matchDay.homeTeamName}</td>
                <td className="print-style">{matchDay.awayTeamName}</td>
                <td className="print-style">{this.formatDatetime(matchDay.matchDateTime)}</td>
                {isUserLoggedIn() && <td className="print-style">{this.renderIsDateTimeConfirmedCheckbox(matchDay, idx)}</td>}
                {isUserLoggedIn() && <td className="not-to-print text-left">{this.renderEditDateTime(matchDay)}</td>}
            </tr>
        );
    }

    formatDate = (date) => {
        return date ? formatBackendDateEtLocale(date) : null;
    };

    formatDatetime = (datetime) => {
        return datetime ? formatBackendDateTimeEtLocale(datetime) : null;
    };

    renderEditDateTime(calendarData) {
        if (!this.isLoggedInUserInPlayingTeam(calendarData) && !this.isLoggedInUserAdmin()) {
            return "";
        }
        return (
            <DateTimeOverlayPopover
                season={this.state.season}
                selectedDateTime={calendarData.matchDateTime}
                matchId={calendarData.matchId}
                forceLeagueCalendarUpdate={this.loadLeagueCalendar}
            />
        );
    }

    renderIsDateTimeConfirmedCheckbox(matchDay, idx) {
        if (!this.isLoggedInUserInPlayingTeam(matchDay) && !this.isLoggedInUserAdmin()) {
            return (
                <Form.Check
                    custom
                    type={`checkbox`}
                    id={`confirm-date-time-checkbox-${idx}`}
                    label={``}
                    defaultChecked={matchDay.isMatchDateTimeConfirmed}
                    disabled
                />
            )
        }
        return (
            <Form.Check
                custom
                type={`checkbox`}
                id={`confirm-date-time-checkbox-${idx}`}
                label={``}
                defaultChecked={matchDay.isMatchDateTimeConfirmed}
                onChange={event => this.updateIsMatchDateTimeConfirmed(event.target.checked, matchDay.matchId)}
            />
        )
    }

    isLoggedInUserInPlayingTeam(matchDay) {
        return matchDay.homeTeamId === this.props.loggedInUserTeamId || matchDay.awayTeamId === this.props.loggedInUserTeamId;
    }

    isLoggedInUserAdmin() {
        return this.props.loggedInUserTeamId === null;
    }

    updateIsMatchDateTimeConfirmed(isChecked, matchId) {
        API.patch(`/match/${matchId}/isMatchDateTimeConfirmed`, {isMatchDateTimeConfirmed: isChecked}).then(() => {
        });
    }
}
