import React, {createContext, useReducer} from 'react';

const initialState = {
    selectedSeason: {},
    selectedLeague: 0,
};
const store = createContext(initialState);
const {Provider} = store;

const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer((prevState, action) => {
        switch (action.type) {
            case 'SET_SELECTED_STATE':
                return {
                    ...prevState,
                    selectedSeason: action.state.selectedSeason,
                    selectedLeague: action.state.selectedLeague
                };
            case 'CLEAR_STATE':
                return initialState;
            default:
                throw new Error("Unsupported store action type");
        }
    }, initialState);

    return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export {store, StateProvider}