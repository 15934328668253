import React, {useContext, useEffect, useState} from 'react';
import '../style/Tabs.css';
import Popup from "reactjs-popup";
import CalendarPopup from "../modules/popup/CalendarPopup";
import MatchDays from "../modules/MatchDays";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {API} from '../api';
import DataTable from "../modules/datatable/Datatable";
import {store} from "../store";
import {getUsername, isUserLoggedIn} from "./user/User";
import {renderSpinner} from "../util/util";
import Datatable from "../modules/datatable/Datatable";


export const Seasons = () => {
    const [seasons, setSeasons] = useState([]);
    const [currentSeason, setCurrentSeason] = useState();
    const [currentLeague, setCurrentLeague] = useState();
    const [loggedInUserTeamId, setLoggedInUserTeamId] = useState();
    const globalState = useContext(store);
    const {dispatch} = globalState;
    const [loading, setLoading] = useState({seasons: false, users: false});
    const [futureMatches, setFutureMatches] = useState([]);
    const [FutureMatchesLoading, setFutureMatchesLoading] = useState(false);

    useEffect(() => {
        setFutureMatchesLoading(true);
        API.get(`/match/future-matches`).then(res => {
            setFutureMatches(res.data);
        }).finally(() => setFutureMatchesLoading(false));
    }, []);

    function showUpcomingMatchesDatatable() {
        return (
            <Datatable
                futureMatches={futureMatches}
                leagueId={currentLeague}
            />
        )
    }

    useEffect(() => {
        setLoading(loaders => ({...loaders, seasons: true}));
        API.get(`/season/seasons`).then(res => {
            res.data.sort((a, b) => b.name.localeCompare(a.name, 'et'));
            res.data.forEach(season => {
                season.leagues.sort((a, b) => b.name.localeCompare(a.name, 'et'));
            })
            setSeasons(res.data);
            if (globalState.state.selectedSeason && globalState.state.selectedLeague) {
                const updatedCurrentSeason = res.data.find(session => session.id === globalState.state.selectedSeason.id);
                const updatedCurrentLeague = updatedCurrentSeason.leagues.find(league => league.id === globalState.state.selectedLeague);
                setCurrentSeason(updatedCurrentSeason);
                setCurrentLeague(updatedCurrentLeague ? updatedCurrentLeague.id : null);
            } else {
                const newCurrentSeason = res.data[0];
                const newCurrentLeague = newCurrentSeason && newCurrentSeason.leagues.length ? newCurrentSeason.leagues[0].id : 0;
                setCurrentSeason(newCurrentSeason);
                setCurrentLeague(newCurrentLeague);
            }
        }).finally(() => {
            setLoading(loaders => ({...loaders, seasons: false}));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isUserLoggedIn()) {
            setLoading(loaders => ({...loaders, users: true}));
            API.get(`/user/users`).then(res => {
                setLoggedInUserTeamId(res.data.find(user => user.username === getUsername()).teamId);
            }).finally(() => {
                setLoading(loaders => ({...loaders, users: false}));
            });
        }
    }, [])

    const handleSeasonChanged = (e) => {
        const season = seasons.find(session => session.id === Number(e.target.value));
        setCurrentSeason(season);
        if (season.leagues.length) {
            setCurrentLeague(season.leagues[0].id);
            dispatch({
                type: 'SET_SELECTED_STATE',
                state: {selectedSeason: season, selectedLeague: season.leagues[0].id}
            });
        } else {
            dispatch({type: 'SET_SELECTED_STATE', state: {selectedSeason: season, selectedLeague: currentLeague}});
        }
    }

    const handleLeagueChanged = (eventKey) => {
        dispatch({
            type: 'SET_SELECTED_STATE',
            state: {selectedSeason: currentSeason, selectedLeague: Number(eventKey)}
        });
        setCurrentLeague(Number(eventKey));
    }

    function mapToLeagueName(id) {
        return currentSeason.leagues.find(league => league.id === id).name;
    }

    if (loading.seasons || loading.users || (isUserLoggedIn() && loggedInUserTeamId === undefined)) {
        return renderSpinner();
    }

    return (
        <>
            <div id="select_season">
                <select className="form-control"
                        value={currentSeason ? currentSeason.id : 0}
                        onChange={handleSeasonChanged}>
                    {seasons.map((season) => <option key={season.id} value={season.id}>{season.name}</option>)}
                </select>
            </div>

            <div>
                {currentSeason && currentSeason.leagues ?
                    <Tabs className="nav_tabs"
                          activeKey={currentLeague}
                          onSelect={handleLeagueChanged}
                          id="season-tabs">

                        {currentSeason.leagues.map(league =>
                            <Tab key={league.id} eventKey={league.id} title={league.name}>
                                <Popup trigger={<button className="btn calendar_btn" onClick={() => {
                                }}> Kalender</button>} modal>
                                    {close => (
                                        <CalendarPopup leagueId={league.id} loggedInUserTeamId={loggedInUserTeamId} closeTrigger={close}/>
                                    )}
                                </Popup>
                                <div className="justify-content-center" style={{maxWidth: "700px", margin: "auto"}}>
                                    <MatchDays leagueId={league.id} currentSeason={currentSeason}/>
                                    <br/>
                                    <DataTable
                                      teams={league.teams}
                                      seasonId={currentSeason.id}
                                      currentLeagueName={currentSeason.leagues.find(l => l.id === currentLeague).name}
                                    />
                                    <>
                                        <Row className={"justify-content-center mt-3 mb-3"}>
                                            <Col md={12}><h3>Järgmised mängud</h3></Col>
                                        </Row>
                                        {FutureMatchesLoading ? renderSpinner() : showUpcomingMatchesDatatable()}
                                    </>
                                </div>
                            </Tab>
                        )}
                    </Tabs>
                    : null}
            </div>
        </>
    );
};

