import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import '../../style/TableActionItems.css';
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export const TableActionItems = (props) => {
    return (
        <div>
            {props.editAction &&
            <FontAwesomeIcon className="action" icon={faEdit} onClick={props.editAction}/>
            }
            {props.deleteAction &&
            <OverlayTrigger
                key="delete-trigger"
                placement="left"
                overlay={
                    <Tooltip id={`tooltip-delete-trigger`}>
                        Kustuta kasutaja
                    </Tooltip>
                }
            >
                <FontAwesomeIcon className="action" icon={faMinusCircle} onClick={props.deleteAction}/>
            </OverlayTrigger>
            }
        </div>);
};