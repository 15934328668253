const YEAR = 0;
const MONTH = 1;
const DAY = 2;
const HOUR = 3;
const MINUTE = 4;

export const createDateFromBackendDate = (backEndDate) => {
    return new Date(backEndDate[YEAR], backEndDate[MONTH] - 1, backEndDate[DAY]);
};

export const createDateTimeFromBackendDate = (backEndDatetime) => {
    if (backEndDatetime === null) {
        return new Date(0, 0, 0);
    }
    return new Date(backEndDatetime[YEAR], backEndDatetime[MONTH] - 1, backEndDatetime[DAY], backEndDatetime[HOUR], backEndDatetime[MINUTE]);
};

export const createNullableDateTimeFromBackendDate = (backEndDatetime) => {
    if (backEndDatetime === null) {
        return new Date(0, 0, 0);
    }
    return new Date(backEndDatetime[YEAR], backEndDatetime[MONTH] - 1, backEndDatetime[DAY], backEndDatetime[HOUR], backEndDatetime[MINUTE]);
};

export const formatBackendDateEtLocale = (backEndDate) => {
    return `${backEndDate[DAY].toString().padStart(2, '0')}.${backEndDate[MONTH].toString().padStart(2, '0')}.${backEndDate[YEAR]}`
};

export const formatBackendDateTimeEtLocale = (backEndDatetime) => {
    return `${backEndDatetime[DAY].toString().padStart(2, '0')}.${backEndDatetime[MONTH].toString().padStart(2, '0')}.${backEndDatetime[YEAR]}
     ${backEndDatetime[HOUR].toString().padStart(2, '0')}:${backEndDatetime[MINUTE].toString().padStart(2, '0')}`
};

export const getCurrentDateWithZeroTime = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
}

export const getCurrentDateMinusDays = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
}

export const convertDateToIsoString = (date) => {
    if (date instanceof Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
    }
    console.error("Only converting from Date object is supported")
};

export const convertDateTimeToIsoString = (datetime) => {
    if (datetime instanceof Date) {
        return new Date(Date.UTC(datetime.getFullYear(), datetime.getMonth(), datetime.getDate(),
            datetime.getHours(), datetime.getMinutes())).toISOString();
    }
    console.error("Only converting from Date object is supported")
};

export const convertIsoStringToDateWithZeroTime = (isoDateTime) => {
    const date = new Date(isoDateTime);
    date.setHours(0, 0, 0, 0);
    return date;
};
