import React, {useEffect, useState} from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {getUsername, isUserAdmin, isUserLoggedIn} from "./user/User";
import {API} from "../api";
import {Button, Row} from "react-bootstrap";
import '../style/Admin.css';
import Datatable from "../modules/datatable/Datatable";
import {SeasonPopup} from "./SeasonPopup";
import {TeamPopup} from "./TeamPopup";
import {getLeagueNamesFromSeasons, renderSpinner} from "../util/util";
import {LeaguePopup} from "./LeaguePopup";
import {UserPopup} from "./UserPopup";
import Col from "react-bootstrap/Col";


export const Admin = () => {
    const [shouldUpdateMatches, setShouldUpdateMatches] = useState(false);
    const [shouldUpdateSeasons, setShouldUpdateSeasons] = useState(false);
    const [shouldUpdateLeagues, setShouldUpdateLeagues] = useState(false);
    const [shouldUpdateTeams, setShouldUpdateTeams] = useState(false);
    const [shouldUpdateUsers, setShouldUpdateUsers] = useState(false);

    const [matches, setMatches] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);

    const [isSeasonPopupVisible, setIsSeasonPopupVisible] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState();

    const [isLeaguePopupVisible, setIsLeaguePopupVisible] = useState(false);
    const [selectedLeague, setSelectedLeague] = useState();

    const [isTeamPopupVisible, setIsTeamPopupVisible] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState();

    const leagueNames = getLeagueNamesFromSeasons(seasons);

    const [isUserPopupVisible, setIsUserPopupVisible] = useState(false);

    const [loading, setLoading] = useState({
        matches: false,
        seasons: false,
        leagues: false,
        teams: false,
        users: false
    });

    useEffect(() => {
        setLoading(l => {
            return {...l, matches: true}
        });
        API.get(`/match/admin-matches`)
            .then(result => {
                setMatches(result.data);
            })
            .finally(() => setLoading(l => {
                return {...l, matches: false}
            }));
    }, [shouldUpdateMatches]);

    useEffect(() => {
        setLoading(l => {
            return {...l, teams: true}
        });
        API.get(`/team/teams`)
            .then(result => {
                setTeams(result.data);
            })
            .finally(() => setLoading(l => {
                return {...l, teams: false}
            }));
    }, [shouldUpdateTeams]);

    useEffect(() => {
        setLoading(l => {
            return {...l, seasons: true}
        });
        API.get(`/season/admin-seasons`)
            .then(result => {
                setSeasons(result.data);
            })
            .finally(() => setLoading(l => {
                return {...l, seasons: false}
            }));
    }, [shouldUpdateSeasons]);

    useEffect(() => {
        setLoading(l => {
            return {...l, leagues: true}
        });
        API.get(`/league/admin-leagues`)
            .then(result => {
                setLeagues(result.data);
            })
            .finally(() => setLoading(l => {
                return {...l, leagues: false}
            }));
    }, [shouldUpdateLeagues]);

    useEffect(() => {
        setLoading(l => {
            return {...l, users: true}
        });
        API.get(`/user/users`)
            .then(result => {
                setUsers(result.data);
            })
            .finally(() => setLoading(l => {
                return {...l, users: false}
            }));
    }, [shouldUpdateUsers]);

    const forceMatchesUpdate = () => {
        setShouldUpdateMatches(!shouldUpdateMatches);
    };

    const forceSeasonsUpdate = () => {
        setShouldUpdateSeasons(!shouldUpdateSeasons);
    };

    const forceLeaguesUpdate = () => {
        setShouldUpdateLeagues(!shouldUpdateLeagues);
    };

    const forceTeamsUpdate = () => {
        setShouldUpdateTeams(!shouldUpdateTeams);
    };

    const forceUsersUpdate = () => {
        setShouldUpdateUsers(!shouldUpdateUsers);
    };

    const editSeason = (seasonId) => {
        setSelectedSeason(seasons.find(season => season.id === seasonId));
        setIsSeasonPopupVisible(true);
    };

    const editLeague = (leagueId) => {
        setSelectedLeague(leagues.find(league => league.id === leagueId));
        setIsLeaguePopupVisible(true);
    };

    const editTeam = (teamId) => {
        setSelectedTeam(teams.find(team => team.id === teamId));
        setIsTeamPopupVisible(true);
    };

    const generateAction = (leagueId) => {
        const data = {leagueId: leagueId};
        API.post(`/calendar/generate`, data)
            .then(() => {
                forceLeaguesUpdate();
                forceMatchesUpdate();
            });
    };

    const generateSecondRoundAction = (leagueId) => {
        const data = {leagueId: leagueId};
        API.post(`/calendar/generateSecondRound`, data)
            .then(() => {
                forceLeaguesUpdate();
                forceMatchesUpdate();
            });
    };

    const deleteUserAction = (userId) => {
        API.delete(`/user/${userId}`)
            .then(() => forceUsersUpdate());
    };

    const handleNewSeasonClick = () => {
        setSelectedSeason(undefined);
        setIsSeasonPopupVisible(true);
    };

    const handleNewTeamClick = () => {
        setSelectedTeam(undefined);
        setIsTeamPopupVisible(true);
    };

    const handleNewUserClick = () => {
        setIsUserPopupVisible(true);
    };

    const renderAdminContent = () => {
        return (
            <>
                <Row className={"justify-content-between"}>
                    <Col xs={12} lg={6} className="text-lg-left text-xs-center">
                        <h3>Matšid</h3>
                    </Col>
                </Row>

                {matches.length > 0 && <Datatable
                    allMatches={matches}
                    paging={true}
                    searching={true}
                    sortable={false}
                    entries={25}
                />}
                {loading.matches && renderSpinner()}

                <Row className={"justify-content-between"}>
                    <Col xs={12} lg={6} className="text-lg-left text-xs-center">
                        <h3>Hooajad</h3>
                    </Col>
                    <Col xs={12} lg={6} className="text-lg-right text-xs-center">
                        <Button
                            variant="primary"
                            onClick={handleNewSeasonClick}
                        >
                            Lisa uus hooaeg
                        </Button>
                    </Col>
                </Row>
                {seasons.length > 0 && <Datatable
                    seasons={seasons}
                    paging={true}
                    editAction={editSeason}
                />}
                {loading.seasons && renderSpinner()}

                <Row className={"justify-content-between mt-5"}>
                    <Col xs={12} lg={6} className="text-lg-left text-xs-center">
                        <h3>Liigad</h3>
                    </Col>
                </Row>
                {leagues.length > 0 &&
                <Datatable
                    leagues={leagues}
                    paging={true}
                    generateAction={generateAction}
                    generateSecondRoundAction={generateSecondRoundAction}
                    editAction={editLeague}
                />}
                {loading.leagues && renderSpinner()}

                <Row className={"justify-content-between mt-5"}>
                    <Col xs={12} lg={6} className="text-lg-left text-xs-center">
                        <h3>Võistkonnad</h3>
                    </Col>
                    <Col xs={12} lg={6} className="text-lg-right text-xs-center">
                        <Button
                            variant="primary"
                            onClick={handleNewTeamClick}
                        >Lisa uus võistkond</Button>
                    </Col>
                </Row>

                {teams.length > 0 &&
                <Datatable
                    actionableTeams={teams}
                    paging={true}
                    editAction={editTeam}
                />}
                {loading.teams && renderSpinner()}

                <Row className={"justify-content-between mt-5"}>
                    <Col xs={12} lg={6} className="text-lg-left text-xs-center">
                        <h3>Kasutajad</h3>
                    </Col>
                    <Col xs={12} lg={6} className="text-lg-right text-xs-center">
                        <Button
                            variant="primary"
                            onClick={handleNewUserClick}
                        >Lisa uus kasutaja</Button>
                    </Col>
                </Row>

                {users.length > 0 &&
                <Datatable
                    users={users}
                    paging={true}
                    deleteAction={deleteUserAction}
                />}
                {loading.users && renderSpinner()}

                <SeasonPopup isSeasonPopupVisible={isSeasonPopupVisible}
                             setIsSeasonPopupVisible={setIsSeasonPopupVisible}
                             selectedSeason={selectedSeason}
                             leagueNames={leagueNames}
                             forceSeasonsUpdate={forceSeasonsUpdate}
                             forceLeaguesUpdate={forceLeaguesUpdate}
                />

                <LeaguePopup isLeaguePopupVisible={isLeaguePopupVisible}
                             setIsLeaguePopupVisible={setIsLeaguePopupVisible}
                             selectedLeague={selectedLeague}
                             allTeams={teams.map(team => ({
                                 id: team.id,
                                 name: team.name,
                                 players: team.players,
                                 seasonScore: null
                             }))}
                             forceLeaguesUpdate={forceLeaguesUpdate}
                />

                <TeamPopup isTeamPopupVisible={isTeamPopupVisible}
                           setIsTeamPopupVisible={setIsTeamPopupVisible}
                           selectedTeam={selectedTeam}
                           forceTeamsUpdate={forceTeamsUpdate}
                />

                <UserPopup allTeams={teams}
                           users={users}
                           isUserPopupVisible={isUserPopupVisible}
                           setIsUserPopupVisible={setIsUserPopupVisible}
                           forceUsersUpdate={forceUsersUpdate}/>
            </>
        );
    };

    const renderUserContent = () => {
        return (
            <>
                <h1 className={"m-5"}>Tere, {getUsername()}!</h1>
                <Row className={"justify-content-between"}>
                    <h3 className="ml-3">Matšid</h3>
                </Row>

                {matches.length > 0 && <Datatable
                    allMatches={matches}
                    paging={true}
                    searching={true}
                    sortable={false}
                    entries={25}
                />
                }
            </>);
    };

    return (
        <div className="admin-wrapper" style={{minHeight: '80vh'}}>
            {isUserAdmin() && renderAdminContent()}
            {!isUserAdmin() && isUserLoggedIn && renderUserContent()}
        </div>
    );
};
