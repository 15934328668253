import React, {useEffect, useState} from 'react';
import {API} from "../api";
import {renderSpinner} from "../util/util";
import Datatable from "../modules/datatable/Datatable";
import {Col, Row} from "react-bootstrap";

export const FutureMatches = () => {
    const [futureMatches, setFutureMatches] = useState([]);
    const [FutureMatchesLoading, setFutureMatchesLoading] = useState(false);

    useEffect(() => {
        setFutureMatchesLoading(true);
        API.get(`/match/future-matches`).then(res => {
            setFutureMatches(res.data);
        }).finally(() => setFutureMatchesLoading(false));
    }, []);

    return (
        <>
            <Row className={"justify-content-center mt-3 mb-3"}>
                <Col md={12}><h3>Eelolevad mängud</h3></Col>
            </Row>
            {FutureMatchesLoading ? renderSpinner() : renderSelectAndDatatable()}
        </>
    );

    function renderSelectAndDatatable() {
        return (
            <Datatable
                futureMatches={futureMatches}
                paging={true}
                searching={true}
                sortable={false}
            />
        )
    }
}
