import React, {useEffect, useState} from 'react';
import Popup from "reactjs-popup";
import {Button, Col, Form, ListGroup, Row} from "react-bootstrap";
import {createTeamNameOptions} from "../util/util";
import {API} from "../api";
import {formatBackendDateEtLocale} from "../util/dateUtil";

export const LeaguePopup = ({selectedLeague, isLeaguePopupVisible, setIsLeaguePopupVisible, allTeams, forceLeaguesUpdate}) => {
    const [selectedName, setSelectedName] = useState("");
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedTeamOption, setSelectedTeamOption] = useState("");

    useEffect(() => {
        if (selectedLeague && isLeaguePopupVisible) {
            setSelectedName(selectedLeague.name);

            if (selectedLeague.teams && selectedLeague.teams.length !== 0) {
                setSelectedTeams([...selectedLeague.teams]);
            }
        }
    }, [selectedLeague, isLeaguePopupVisible]);

    const resetAndClose = () => {
        setSelectedTeams([]);
        setSelectedName("");
        return setIsLeaguePopupVisible(false);
    };

    const handleSave = () => {
        const data = {
            name: selectedName,
            teams: selectedTeams
        };
        API.patch(`/league/${selectedLeague.id}`, data)
            .then(() => {
                    forceLeaguesUpdate();
                    resetAndClose();
                }
            );
    };

    const removeTeamFromLeague = (team) => {
        const index = selectedTeams.indexOf(team);
        if (index > -1) {
            selectedTeams.splice(index, 1);
        }
        setSelectedTeams([...selectedTeams]);
    };

    const addTeamToLeague = (teamId) => {
        if (!teamId) {
            return;
        }
        const teamIdNumber = parseInt(teamId, 10);
        const newTeam = allTeams.find(team => team.id === teamIdNumber);
        const teamIdInSelectedTeams = selectedTeams.find(team => team.id === teamIdNumber);
        if (newTeam && !teamIdInSelectedTeams) {
            selectedTeams.push(newTeam);
            setSelectedTeams([...selectedTeams]);
        }
    };

    const createSelectedTeamNameList = () => {
        return selectedTeams.map(team => (
            createListGroupItem(team)
        ));
    };

    const createListGroupItem = (selectedTeam) => {
        return <ListGroup.Item
            className="d-flex justify-content-between align-items-center"
            key={selectedTeam.id + "-groupItem"}
        >
            <b className={"mr-2"} style={{minWidth: 'fit-content'}}>{selectedTeam.name}</b>
            <span>{selectedTeam.players &&
            selectedTeam.players
                .map(player => player.firstName + " " + player.lastName)
                .join(", ")}
            </span>
            <Button
                variant={'light'}
                onClick={() => removeTeamFromLeague(selectedTeam)}
                disabled={selectedLeague && selectedLeague.hasCalendar}
            >
                Eemalda
            </Button>
        </ListGroup.Item>;
    };

    return (
        <Popup
            open={isLeaguePopupVisible}
            onClose={() => resetAndClose()} modal>
            {close => (
                <div>
                    <button className="close-modal" onClick={close}>
                        &times;
                    </button>
                    <div><br /></div><>
                    <h3 className={"m-3"}>
                        Liiga muutmine
                    </h3>

                    <Form className="popup-form">
                        <Row>
                            <Col md={8} className={"offset-md-2"}>
                                <Form.Group controlId="formLeagueName">
                                    <Form.Label>Liiga nimi</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Sisesta nimi"
                                        value={selectedName}
                                        onChange={event => setSelectedName(event.target.value)}
                                        disabled={selectedLeague && selectedLeague.hasCalendar}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} className={"offset-md-2"}>
                                <Form.Group controlId="formLeagueSeasonText">
                                    <Form.Label>Hooaeg</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={selectedLeague && formatBackendDateEtLocale(selectedLeague.seasonStart) + ' - ' + formatBackendDateEtLocale(selectedLeague.seasonEnd)}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} className={"offset-md-2"}>
                                <Form.Group controlId="formTeamOption">
                                    <Form.Label>Võistkonnad</Form.Label>
                                    <Row>
                                        <Col md={10}>
                                            <Form.Control
                                                as="select"
                                                placeholder="Vali võistkond"
                                                onChange={event => setSelectedTeamOption(event.target.value)}
                                                disabled={selectedLeague && selectedLeague.hasCalendar}
                                            >
                                                {createTeamNameOptions(allTeams)}
                                            </Form.Control>
                                        </Col>
                                        <Col md={2}>
                                            <Button
                                                className={"mt-0"}
                                                variant="light"
                                                onClick={() => addTeamToLeague(selectedTeamOption)}
                                                disabled={selectedLeague && selectedLeague.hasCalendar}
                                            >
                                                Lisa
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} className={"offset-md-2"}>
                                <ListGroup>
                                    {createSelectedTeamNameList()}
                                </ListGroup>
                            </Col>
                        </Row>

                        <Button
                            className="mt-3"
                            variant="primary"
                            onClick={handleSave}
                            disabled={selectedLeague && selectedLeague.hasCalendar}
                        >
                            Salvesta
                        </Button>
                    </Form>
                </>
                </div>
            )}

        </Popup>
    );
};