import React, {Component} from "react"
import {MDBTable, MDBTableBody} from "mdbreact";


class SetMatchDetailInput extends Component {

    render() {
        let {setDetailRequests, players, movedPlayers, gameIndex, isHome} = this.props;
        let prefix = isHome ? 'home' : 'away';
        let sets = [];
        for (let idx = 0; idx < setDetailRequests.length; idx++) {
            let playerId = `${prefix}PlayerId-${idx}`, setClassicPins = `${prefix}SetClassicPins-${idx}`,
                setMiss = `${prefix}SetMissPins-${idx}`, setTotalPins = `${prefix}SetTotalPins-${idx}`,
                setNo = `setNo-${idx}`;
            sets.push(
                <div key={"" + gameIndex + "" + idx}>
                    <div className="match_content">
                        <div className="form-group no_bottom_margin">
                            <label hidden htmlFor={playerId}>Vooru number</label>
                            <input
                                type="number"
                                hidden={true}
                                readOnly={true}
                                name={setNo}
                                data-id={idx}
                                data-gameno={gameIndex}
                                id={setNo}
                                value={setDetailRequests[idx].setNo}
                                className="setNo"
                                ref={() => {
                                    setDetailRequests[idx].setNo = idx + 1
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group no_bottom_margin">
                        <MDBTable striped className="merged_tables">
                            <MDBTableBody>
                                <tr>
                                    {this.renderPlayerSelection(idx, playerId, gameIndex, players, movedPlayers, setDetailRequests, isHome)}
                                    {this.renderClassicPinsInput(setClassicPins, idx, gameIndex, setDetailRequests, isHome)}
                                    {this.renderSetMissInput(setMiss, idx, gameIndex, setDetailRequests, isHome)}
                                    {this.renderSetTotalPinsInput(setTotalPins, idx, gameIndex, setDetailRequests, isHome)}
                                </tr>
                                {this.renderGameTotals(idx, setDetailRequests, isHome)}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
            );
        }
        return sets;
    }

    renderSetTotalPinsInput(setTotalPins, idx, gameIndex, setDetailRequests, isHome) {
        return <td width="45px" height="50px">
            <input
                type="number"
                name={setTotalPins}
                data-id={idx}
                data-gameno={gameIndex}
                id={setTotalPins}
                defaultValue={isHome ? setDetailRequests[idx].homeSetTotalPins : setDetailRequests[idx].awaySetTotalPins}
                className={isHome ? "homeSetTotalPins" : "awaySetTotalPins"}
            />
        </td>;
    }

    renderSetMissInput(setMiss, idx, gameIndex, setDetailRequests, isHome) {
        return <td width="45px" height="50px">
            <input
                type="number"
                name={setMiss}
                data-id={idx}
                data-gameno={gameIndex}
                id={setMiss}
                defaultValue={isHome ? setDetailRequests[idx].homeSetMiss : setDetailRequests[idx].awaySetMiss}
                className={isHome ? "homeSetMiss" : "awaySetMiss"}
            />
        </td>;
    }

    renderClassicPinsInput(setClassicPins, idx, gameIndex, setDetailRequests, isHome) {
        return <td width="45px" height="50px">
            <input
                type="number"
                name={setClassicPins}
                data-id={idx}
                data-gameno={gameIndex}
                id={setClassicPins}
                defaultValue={isHome ? setDetailRequests[idx].homeSetClassicPins : setDetailRequests[idx].awaySetClassicPins}
                className={isHome ? "homeSetClassicPins" : "awaySetClassicPins"}
            />
        </td>;
    }

    renderPlayerSelection(idx, playerId, gameIndex, players, movedPlayers, setDetailRequests, isHome) {
        if (idx === 0) {
            players.sort((a, b) => a.firstName.localeCompare(b.firstName, 'et') || a.lastName.localeCompare(b.lastName, 'et'));
            movedPlayers.sort((a, b) => a.playerFirstName.localeCompare(b.playerFirstName, 'et') || a.playerLastName.localeCompare(b.playerLastName, 'et'));
            return <td width="120px">
                <select
                    name={playerId}
                    data-id={idx}
                    data-gameno={gameIndex}
                    id={playerId}
                    className={isHome ? "homePlayerId" : "awayPlayerId"}
                    defaultValue={isHome ? setDetailRequests[idx].homePlayerId : setDetailRequests[idx].awayPlayerId}
                >
                    <option value=""/>
                    {players.map((player) =>
                        <option key={"" + gameIndex + "" + isHome + "" + player.id}
                                value={player.id}>{player.firstName} {player.lastName}</option>
                    )}
                    {movedPlayers.map(movedPlayer =>
                        <option disabled key={"" + gameIndex + "" + isHome + "" + movedPlayer.playerId}
                                 value={movedPlayer.playerId}>{movedPlayer.playerFirstName} {movedPlayer.playerLastName}*</option>
                    )}
                </select>
            </td>;
        } else {
            return <td width="120px">
                <input
                    type="number"
                    hidden={true}
                    name={playerId}
                    data-id={idx}
                    data-gameno={gameIndex}
                    id={playerId + "-hidden"}
                    readOnly={true}
                    value={isHome ? setDetailRequests[0].homePlayerId : setDetailRequests[0].awayPlayerId}
                    className={isHome ? "homePlayerId" : "awayPlayerId"}
                    ref={() => {
                        if (isHome) {
                            setDetailRequests[idx].homePlayerId = setDetailRequests[0].homePlayerId;
                        } else {
                            setDetailRequests[idx].awayPlayerId = setDetailRequests[0].awayPlayerId;
                        }
                    }}
                />
            </td>;
        }
    }

    renderGameTotals(idx, setDetailRequests, isHome) {
        const LAST_SET = 3;
        if (idx === LAST_SET) {
            return (
                <tr>
                    <td>Mäng kokku</td>
                    <td>
                        {setDetailRequests
                            .slice(idx - 3, idx + 1)
                            .map(set => isHome ? Number(set.homeSetClassicPins) : Number(set.awaySetClassicPins))
                            .reduce((accumulator, current) => accumulator + current)}
                    </td>
                    <td>
                        {setDetailRequests
                            .slice(idx - 3, idx + 1)
                            .map(set => isHome ? Number(set.homeSetMiss) : Number(set.awaySetMiss))
                            .reduce((accumulator, current) => accumulator + current)}
                    </td>
                    <td>
                        {setDetailRequests
                            .slice(idx - 3, idx + 1)
                            .map(set => isHome ? Number(set.homeSetTotalPins) : Number(set.awaySetTotalPins))
                            .reduce((accumulator, current) => accumulator + current)}
                    </td>
                </tr>
            );
        }
    }
}

export default SetMatchDetailInput;