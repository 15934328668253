import React, {useEffect, useState} from 'react';
import {API} from "../api";
import {renderSpinner} from "../util/util";
import Datatable from "../modules/datatable/Datatable";
import {Row} from "react-bootstrap";

export const Clubs = () => {
    const [clubs, setClubs] = useState([]);
    const [clubMembers, setClubMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        API.get(`/club/clubs`)
            .then(result => {
                setClubs(result.data);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        API.get(`/club/clubMembers`)
            .then(result => {
                setClubMembers(result.data);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <Row className={"justify-content-center mt-3"}>
                <h3 className="ml-3">Klubid</h3>
            </Row>
            {loading && renderSpinner()}
            {clubs.length > 0 && <Datatable clubs={clubs}/>}

            <Row className={"justify-content-center mt-3"}>
                <h3 className="ml-3">Klubiliikmete nimekiri</h3>
            </Row>
            {clubs.length > 0 &&
            <Datatable
                clubMembers={clubMembers}
                paging={true}
                searching={true}
                sortable={true}
            />
            }
        </>
    );
}