import React, {useEffect, useState} from 'react';
import Popup from "reactjs-popup";
import {Alert, Button, Col, Form, ListGroup, Row} from "react-bootstrap";
import {API} from "../api";
import DatePicker from "react-datepicker/es";
import {et} from "date-fns/esm/locale";
import {convertDateToIsoString} from "../util/dateUtil";
import {PlayerItem} from "./PlayerItem";

export const TeamPopup = ({selectedTeam, isTeamPopupVisible, setIsTeamPopupVisible, forceTeamsUpdate}) => {
    const [shouldUpdateClubsAndPlayers, setShouldUpdateClubsAndPlayers] = useState(false);

    const [selectedName, setSelectedName] = useState("");
    const [selectedClub, setSelectedClub] = useState();
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedPlayerOption, setSelectedPlayerOption] = useState();
    const [selectedClubOption, setSelectedClubOption] = useState();
    const [selectedClubText, setSelectedClubText] = useState();

    const [selectedFirstName, setSelectedFirstName] = useState("");
    const [selectedLastName, setSelectedLastName] = useState("");
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState();
    const [selectedGender, setSelectedGender] = useState("MALE");

    const [allClubs, setAllClubs] = useState([]);
    const [allPlayers, setAllPlayers] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (selectedTeam && isTeamPopupVisible) {
            setSelectedName(selectedTeam.name);
            if (selectedTeam.clubName) {
                setSelectedClub(selectedTeam.clubName);
            }

            if (selectedTeam.players && selectedTeam.players.length !== 0) {
                setSelectedPlayers([...selectedTeam.players]);
            }
        }
    }, [selectedTeam, isTeamPopupVisible]);

    useEffect(() => {
        API.get(`/club/clubs`)
            .then(result => {
                setAllClubs(result.data);
            });
    }, [shouldUpdateClubsAndPlayers]);

    useEffect(() => {
        API.get(`/player/players`)
            .then(result => {
                setAllPlayers(result.data);
            });
    }, [shouldUpdateClubsAndPlayers]);

    const forceClubsAndPlayersUpdate = () => {
        setShouldUpdateClubsAndPlayers(!shouldUpdateClubsAndPlayers);
    };

    const handleSave = () => {
        setErrorMessage("");
        if (!selectedName) {
            setErrorMessage("Võistkonnal peab olema nimi");
            return;
        }
        if (!selectedClub) {
            setErrorMessage("Klubi valik on kohustuslik");
            return;
        }
        const oldClub = allClubs.find(club => club.name === selectedClub);
        if (selectedTeam) {
            const data = {
                id: selectedTeam.id,
                name: selectedName,
                clubId: oldClub ? oldClub.id : null,
                clubName: selectedClub,
                players: selectedPlayers
            };

            API.patch(`/team/${selectedTeam.id}`, data)
                .catch(reason => {
                    if (reason.response && reason.response.data) {
                        setErrorMessage(reason.response.data.message);
                    }
                })
                .finally(() => {
                    forceClubsAndPlayersUpdate();
                    forceTeamsUpdate();
                    setIsTeamPopupVisible(false);
                });
        } else {
            const data = {
                name: selectedName,
                clubId: oldClub ? oldClub.id : null,
                clubName: selectedClub,
                players: selectedPlayers
            };

            API.post(`/team/`, data)
                .catch(reason => {
                    if (reason.response && reason.response.data) {
                        setErrorMessage(reason.response.data.message);
                    }
                })
                .finally(() => {
                    forceClubsAndPlayersUpdate();
                    forceTeamsUpdate();
                    setIsTeamPopupVisible(false);
                });
        }
    };

    const createClubNameOptions = () => {
        const options = [<option key={"empty-option"} value=""/>];
        allClubs.sort((a, b) => a.name.localeCompare(b.name, 'et'));

        const existingOptions = [...allClubs].map(club => <option key={club.id + "-option"}
                                                                  value={club.name}>
            {club.name}
        </option>);
        options.push(existingOptions);
        return options;
    };

    const resetAndClose = () => {
        setSelectedPlayers([]);
        setSelectedClub(undefined);
        setSelectedName("");
        setSelectedName("");
        setSelectedDateOfBirth(undefined);
        setSelectedFirstName("");
        setSelectedLastName("");
        setSelectedGender("FEMALE");
        setSelectedPlayerOption(undefined);
        setErrorMessage("");
        return setIsTeamPopupVisible(false);
    };

    const removePlayerFromTeam = (player) => {
        const index = selectedPlayers.indexOf(player);
        if (index > -1) {
            selectedPlayers.splice(index, 1);
        }
        setSelectedPlayers([...selectedPlayers]);
    };

    const checkPlayerEqualityByNameAndDateOfBirth = (player, selectedPlayer) => {
        return player.firstName === selectedPlayer.firstName
            && player.lastName === selectedPlayer.lastName
            && player.dateOfBirth === selectedPlayer.dateOfBirth;
    };

    const changeTeamCaptain = (selectedPlayer) => {
        let newTeamCaptain = selectedPlayers.find(player => player.id === selectedPlayer.id);
        if (!selectedPlayer.id) {
            newTeamCaptain = selectedPlayers.find(player => checkPlayerEqualityByNameAndDateOfBirth(player, selectedPlayer));
        }

        const oldTeamCaptain = selectedPlayers.find(player => player.isCaptain);
        if (oldTeamCaptain) {
            oldTeamCaptain.isCaptain = false;
        }
        newTeamCaptain.isCaptain = true;
        setSelectedPlayers([...selectedPlayers]);
    };


    const addPlayerToTeam = (playerId) => {
        if (!playerId) {
            return;
        }
        const playedIdNumber = parseInt(playerId, 10);
        const newPlayer = allPlayers.find(player => player.id === playedIdNumber);
        const playerIdInSelectedPlayers = selectedPlayers.find(player => player.id === playedIdNumber);
        if (newPlayer && !playerIdInSelectedPlayers) {
            newPlayer.isCaptain = false;
            selectedPlayers.push(newPlayer);
            setSelectedPlayers([...selectedPlayers]);
        }
    };

    const createPlayerNameOptions = () => {
        const options = [<option key={"empty-option"} value=""/>];

        allPlayers.sort((a, b) => a.firstName.localeCompare(b.firstName, 'et') || a.lastName.localeCompare(b.lastName, 'et'));
        const existingOptions = allPlayers.map(player =>
            <option key={player.id + "-option"} value={player.id}>{player.firstName + " " + player.lastName}</option>
        );
        options.push(existingOptions);
        return options;
    };

    const createSelectedPlayerNameList = () => {
        return selectedPlayers.map((player, idx) => (
            createPlayerItem(player, idx)
        ));
    };

    const createPlayerItem = (selectedPlayer, idx) => {
        return <PlayerItem
            key={idx}
            selectedPlayer={selectedPlayer}
            changeTeamCaptain={changeTeamCaptain}
            removePlayerFromTeam={removePlayerFromTeam}
            updatePlayerInfo={updatePlayerInfo}
        />
    };

    const addNewPlayer = () => {
        const player = {
            firstName: selectedFirstName,
            lastName: selectedLastName,
            gender: selectedGender,
            dateOfBirth: convertDateToIsoString(selectedDateOfBirth)
        };
        selectedPlayers.push(player);
        setSelectedPlayers([...selectedPlayers]);
        setSelectedDateOfBirth(undefined);
        setSelectedFirstName("");
        setSelectedLastName("");
        setSelectedGender("FEMALE");
    };

    const updatePlayerInfo = (player) => {
        const updatedPlayers = selectedPlayers;
        const updatedPlayer = updatedPlayers.find(p => p.id === player.id);
        updatedPlayer.firstName = player.firstName;
        updatedPlayer.lastName = player.lastName;
        updatedPlayer.dateOfBirth = player.dateOfBirth;
        updatedPlayer.gender = player.gender;
        setSelectedPlayers(updatedPlayers);
    };

    return (
        <Popup
            open={isTeamPopupVisible}
            onClose={() => resetAndClose()} modal>
            {close => (
                <div>
                    <button className="close-modal" onClick={close}>
                        &times;
                    </button>
                    <div><br /></div>
                    <>
                        <h3 className={"m-3"}>
                            {selectedTeam ? 'Võistkonna muutmine' : 'Võistkonna lisamine'}
                        </h3>

                        <Form className="popup-form">
                            <Row>
                                <Col md={10} className={"offset-md-1"}>
                                    <Form.Group controlId="formLeagueName">
                                        <Form.Label>Võistkonna nimi</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Sisesta nimi"
                                            value={selectedName}
                                            onChange={event => setSelectedName(event.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={10} className={"offset-md-1"}>
                                    <Form.Group>
                                        <Form.Label>Klubi</Form.Label>
                                        <Row>
                                            <Col md={10}>
                                                <Form.Control
                                                    as="select"
                                                    placeholder="Vali"
                                                    onChange={event => setSelectedClubOption(event.target.value)}
                                                >
                                                    {createClubNameOptions()}
                                                </Form.Control>
                                            </Col>
                                            <Col md={2}>
                                                <Button className={"mt-0"} variant="light"
                                                        onClick={() => setSelectedClub(selectedClubOption)}>
                                                    Vali
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={10}>
                                                <Form.Control
                                                    className="mt-2"
                                                    type="text"
                                                    placeholder="Sisesta nimi"
                                                    onChange={event => setSelectedClubText(event.target.value)}
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Button variant="light" onClick={() => setSelectedClub(selectedClubText)}>
                                                    Vali
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Form.Text className="text-muted">
                                            Vali eksisteeriv klubi või loo uus.
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={10} className={"offset-md-1"}>
                                    <ListGroup>
                                        {selectedClub && <ListGroup.Item
                                            className="d-flex justify-content-left align-items-center"
                                            key={selectedClub + "-groupItem"}
                                        >
                                            <b>Klubi: </b><span className={"ml-2"}>{selectedClub}</span>
                                        </ListGroup.Item>}
                                    </ListGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={10} className={"offset-md-1 mt-3"}>
                                    <Form.Group>
                                        <Form.Label>Mängijad</Form.Label>
                                        <Row>
                                            <Col md={10}>
                                                <Form.Control
                                                    as="select"
                                                    placeholder="Vali mängijad"
                                                    onChange={event => setSelectedPlayerOption(event.target.value)}
                                                >
                                                    {createPlayerNameOptions()}
                                                </Form.Control>
                                            </Col>
                                            <Col md={2}>
                                                <Button
                                                    className={"mt-0"}
                                                    variant="light"
                                                    onClick={() => addPlayerToTeam(selectedPlayerOption)}
                                                >
                                                    Lisa
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xl={3}>
                                                <Form.Control
                                                    className="mt-2 mb-2"
                                                    type="text"
                                                    placeholder="Sisesta eesnimi"
                                                    value={selectedFirstName}
                                                    onChange={event => setSelectedFirstName(event.target.value)}
                                                />
                                            </Col>
                                            <Col xl={3}>
                                                <Form.Control
                                                    className="mt-2 mb-2"
                                                    type="text"
                                                    placeholder="Sisesta perenimi"
                                                    value={selectedLastName}
                                                    onChange={event => setSelectedLastName(event.target.value)}
                                                />
                                            </Col>
                                            <Col xl={2}>
                                                <DatePicker
                                                    className="mt-2 mb-2"
                                                    selected={selectedDateOfBirth}
                                                    locale={et}
                                                    dateFormat="dd.MM.yyyy"
                                                    placeholderText="Sünnikuupäev"
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    maxDate={new Date()}
                                                    onChange={date => setSelectedDateOfBirth(date)}/>
                                            </Col>
                                            <Col xl={2}>
                                                <Form.Control
                                                    className="mt-2 mb-2"
                                                    as="select"
                                                    placeholder="Vali sugu"
                                                    value={selectedGender}
                                                    onChange={event => setSelectedGender(event.target.value)}
                                                >
                                                    <option key={"male-option"} value="MALE">Mees</option>
                                                    <option key={"female-option"} value="FEMALE">Naine</option>
                                                </Form.Control>
                                            </Col>
                                            <Col xl={2}>
                                                <Button
                                                    variant="light"
                                                    onClick={() => addNewPlayer()}
                                                    disabled={!selectedFirstName || !selectedLastName || !selectedDateOfBirth}
                                                >
                                                    Lisa
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Form.Text className="text-muted">
                                            Vali eksisteeriv mängija või loo uus.
                                        </Form.Text>

                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={10} className={"offset-md-1"}>
                                    <ListGroup>
                                        {createSelectedPlayerNameList()}
                                    </ListGroup>
                                </Col>
                            </Row>

                            <Button
                                className="mt-3"
                                variant="primary"
                                onClick={handleSave}
                            >
                                Salvesta
                            </Button>

                            {errorMessage &&
                            <Alert variant="danger" className={"m-3"}>
                                {errorMessage}
                            </Alert>
                            }
                        </Form>
                    </>
                </div>
            )}

        </Popup>
    );
};
