import React from 'react';
import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";

export const MatchDetailsTable = ({matchData}) => {
    const matchDetails = matchData.matchDetails;

    if (!matchDetails || !matchDetails.length) {
        return (
            <div>
                Mängu info puudub
            </div>
        )
    }

    let homeTotal = {classic: 0, system: 0, miss: 0, total: 0, setPoint: 0, teamPoint: 0, extraTeamPoints: 0};
    let awayTotal = {classic: 0, system: 0, miss: 0, total: 0, setPoint: 0, teamPoint: 0, extraTeamPoints: 0};

    matchDetails.forEach(match => {
        match.homePlayer.sets.forEach((set) => {
            if (!set.setNo) {
                homeTotal.classic += set.classic;
                homeTotal.system += set.system;
                homeTotal.miss += set.miss;
                homeTotal.total += set.total;
                homeTotal.setPoint += set.setPoint;
                homeTotal.teamPoint += set.teamPoint;
            }
        })

        match.awayPlayer.sets.forEach((set) => {
            if (!set.setNo) {
                awayTotal.classic += set.classic;
                awayTotal.system += set.system;
                awayTotal.miss += set.miss;
                awayTotal.total += set.total;
                awayTotal.setPoint += set.setPoint;
                awayTotal.teamPoint += set.teamPoint;
            }
        })
    })

    homeTotal.extraTeamPoints = matchData.homePoints - homeTotal.teamPoint
    awayTotal.extraTeamPoints = matchData.awayPoints - awayTotal.teamPoint

    return (
        <div>
            {matchDetails.map((game, idx) =>
                <div key={idx}>
                    <div className="wrapper">
                        <div className="home_player">
                            <MDBTable striped className="content"
                                      style={{maxWidth: '400px', float: 'right', minWidth: '300px'}}>
                                <MDBTableHead color="blue" textWhite>
                                    <tr>
                                        <th width="15px">{game.gameNo}</th>
                                        <th>{game.homePlayer.playerFirstName} {game.homePlayer.playerLastName}</th>
                                        <th width="15px">KL</th>
                                        <th width="15px">SÜ</th>
                                        <th width="15px">M</th>
                                        <th width="15px">Kokku</th>
                                        <th width="15px">SP</th>
                                        <th width="15px">MP</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {game.homePlayer.sets.map((set, idx) =>
                                        <tr key={idx}>
                                            <td/>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{!set.setNo ? 'Kokku' : getSubstitute(set.setNo, game.gameNo, matchData, matchData.homeTeamId)}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.classic}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.system}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.miss}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.total}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.setPoint}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{!set.setNo ? set.teamPoint : null}</td>
                                        </tr>
                                    )}
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                        <div className="guest_player">
                            <MDBTable striped className="content" style={{maxWidth: '400px', minWidth: '300px'}}>
                                <MDBTableHead color="blue" textWhite>
                                    <tr>
                                        <th width="15px">{game.gameNo}</th>
                                        <th>{game.awayPlayer.playerFirstName} {game.awayPlayer.playerLastName}</th>
                                        <th width="15px">KL</th>
                                        <th width="15px">SÜ</th>
                                        <th width="15px">M</th>
                                        <th width="15px">Kokku</th>
                                        <th width="15px">SP</th>
                                        <th width="15px">MP</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {game.awayPlayer.sets.map((set, idx) =>
                                        <tr key={idx}>
                                            <td/>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{!set.setNo ? 'Kokku' : getSubstitute(set.setNo, game.gameNo, matchData, matchData.awayTeamId)}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.classic}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.system}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.miss}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.total}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{set.setPoint}</td>
                                            <td style={{fontWeight: !set.setNo ? '400' : '300'}}>{!set.setNo ? set.teamPoint : null}</td>
                                        </tr>
                                    )}
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </div>
                </div>
            )}
            <div className="wrapper" style={{marginTop: '45px'}}>
                <div className="home_player">
                    <MDBTable striped className="content"
                              style={{maxWidth: '400px', float: 'right', minWidth: '300px'}}>
                        <MDBTableHead color="blue" textWhite>
                            <tr>
                                <th width="15px"></th>
                                <th></th>
                                <th width="15px">KL</th>
                                <th width="15px">SÜ</th>
                                <th width="15px">M</th>
                                <th width="15px">Kokku</th>
                                <th width="15px">SP</th>
                                <th width="15px">MP</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <tr>
                                <td/>
                                <td style={{fontWeight: '400'}}>{'Kokku'}</td>
                                <td style={{fontWeight: '400'}}>{homeTotal.classic}</td>
                                <td style={{fontWeight: '400'}}>{homeTotal.system}</td>
                                <td style={{fontWeight: '400'}}>{homeTotal.miss}</td>
                                <td style={{fontWeight: '400'}}>{homeTotal.total}</td>
                                <td style={{fontWeight: '400'}}>{homeTotal.setPoint}</td>
                                <td style={{fontWeight: '400'}}>{homeTotal.teamPoint}</td>
                            </tr>
                            <tr>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td style={{fontWeight: '400'}}>{homeTotal.extraTeamPoints}</td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                </div>
                <div className="guest_player">
                    <MDBTable striped className="content" style={{maxWidth: '400px', minWidth: '300px'}}>
                        <MDBTableHead color="blue" textWhite>
                            <tr>
                                <th width="15px"></th>
                                <th></th>
                                <th width="15px">KL</th>
                                <th width="15px">SÜ</th>
                                <th width="15px">M</th>
                                <th width="15px">Kokku</th>
                                <th width="15px">SP</th>
                                <th width="15px">MP</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <tr>
                                <td/>
                                <td style={{fontWeight: '400'}}>{'Kokku'}</td>
                                <td style={{fontWeight: '400'}}>{awayTotal.classic}</td>
                                <td style={{fontWeight: '400'}}>{awayTotal.system}</td>
                                <td style={{fontWeight: '400'}}>{awayTotal.miss}</td>
                                <td style={{fontWeight: '400'}}>{awayTotal.total}</td>
                                <td style={{fontWeight: '400'}}>{awayTotal.setPoint}</td>
                                <td style={{fontWeight: '400'}}>{awayTotal.teamPoint}</td>
                            </tr>
                            <tr>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td style={{fontWeight: '400'}}>{awayTotal.extraTeamPoints}</td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                </div>
            </div>
        </div>);
};

function getSubstitute(setNo, gameNo, matchData, teamId) {
    let subs = matchData.substitutions;
    if (subs) {
        for (const sub of subs) {
            if (sub.substitutionGameNo === gameNo && Math.ceil(sub.substitutionEntered / 30) === setNo && teamId === sub.teamId) {
                return sub.playerFirstName + " " + sub.playerLastName + " (" + sub.substitutionEntered + ")";
            }
        }
    }
    return "";
}
