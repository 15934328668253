import React, {useEffect, useState} from 'react';
import Popup from "reactjs-popup";
import {Alert, Button, Col, Form, ListGroup, Row} from "react-bootstrap";
import {getLeagueNamesFromSeasons} from "../util/util";
import {API} from "../api";
import DatePicker from "react-datepicker/es";
import {et} from "date-fns/esm/locale";
import {convertDateToIsoString, createDateFromBackendDate, getCurrentDateWithZeroTime} from "../util/dateUtil";

export const SeasonPopup = ({
                                selectedSeason, isSeasonPopupVisible, setIsSeasonPopupVisible, leagueNames,
                                forceSeasonsUpdate, forceLeaguesUpdate
                            }) => {
    const [selectedLeagues, setSelectedLeagues] = useState([]);
    const [selectedLeagueOption, setSelectedLeagueOption] = useState("");
    const [selectedLeagueText, setSelectedLeagueText] = useState("");
    const [selectedName, setSelectedName] = useState("");
    const [selectedStartDate, setSelectedStartDate] = useState(getCurrentDateWithZeroTime());
    const [selectedEndDate, setSelectedEndDate] = useState(getCurrentDateWithZeroTime());

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (selectedSeason && isSeasonPopupVisible) {
            setSelectedName(selectedSeason.name);
            setSelectedStartDate(createDateFromBackendDate(selectedSeason.startDate));
            setSelectedEndDate(createDateFromBackendDate(selectedSeason.endDate));

            let selectedSeasonLeagueNames = [];
            if (selectedSeason.length !== 0) {
                selectedSeasonLeagueNames = [...getLeagueNamesFromSeasons([selectedSeason])];
                setSelectedLeagues([...selectedSeasonLeagueNames]);
            }

        }
    }, [selectedSeason, isSeasonPopupVisible]);

    const createLeagueNameOptions = () => {
        const options = [<option key={"empty-option"} value=""/>];
        const sortedLeagueNames = [...leagueNames].sort((a, b) => a.localeCompare(b, 'et'));
        const existingOptions = sortedLeagueNames.map(leagueName => <option key={leagueName + "-option"}
                                                                           value={leagueName}>{leagueName}</option>);
        options.push(existingOptions);
        return options;
    };

    function createListGroupItem(selectedLeague) {
        let leagueObject = null;
        if (selectedSeason && selectedSeason.leagues) {
            leagueObject = selectedSeason.leagues.find(league => league.name === selectedLeague);
        }
        const shouldBeDisabled = leagueObject && leagueObject.teams !== null && leagueObject.teams.length !== 0;
        return <ListGroup.Item
            className="d-flex justify-content-between align-items-center"
            key={selectedLeague + "-option"}
        >
            {selectedLeague} <Button
            variant={'light'}
            onClick={() => removeLeagueFromSeason(selectedLeague)}
            disabled={shouldBeDisabled}
        >
            Eemalda
        </Button>
        </ListGroup.Item>;
    }

    const createSelectedLeagueNameList = () => {
        return selectedLeagues.map(selectedLeague => (
            createListGroupItem(selectedLeague)
        ));
    };

    const addLeagueToSeason = (league) => {
        if (!league) {
            return;
        }
        const leagueSet = new Set(selectedLeagues);
        leagueSet.add(league);
        setSelectedLeagues([...leagueSet]);
    };

    const removeLeagueFromSeason = (league) => {
        const index = selectedLeagues.indexOf(league);
        if (index > -1) {
            selectedLeagues.splice(index, 1);
        }
        setSelectedLeagues([...selectedLeagues]);
    };

    const resetAndClose = () => {
        setSelectedLeagues([]);
        setSelectedStartDate(getCurrentDateWithZeroTime());
        setSelectedEndDate(getCurrentDateWithZeroTime());
        setSelectedName("");
        setErrorMessage("");
        return setIsSeasonPopupVisible(false);
    };

    const handleSave = () => {
        setErrorMessage("");
        if (!selectedName) {
            setErrorMessage("Hooajal peab olema nimi");
            return;
        }
        if (!selectedStartDate || !selectedEndDate) {
            setErrorMessage("Hooajal peab olema algus- ja lõppkuupäev");
            return;
        }
        if (selectedEndDate <= selectedStartDate) {
            setErrorMessage("Hooaja lõppkuupäev peab olema pärast alguskuupäeva");
            return;
        }

        if (selectedSeason) {
            const data = {
                id: selectedSeason.id,
                name: selectedName,
                startDate: convertDateToIsoString(selectedStartDate),
                endDate: convertDateToIsoString(selectedEndDate)
            };

            API.patch(`/season/${selectedSeason.id}`, data)
                .then(res => {
                    const previousLeagueNames = [...getLeagueNamesFromSeasons([selectedSeason])];
                    const removedLeagues = selectedSeason.leagues.filter(league => !selectedLeagues.includes(league.name));
                    const addedLeagueNames = selectedLeagues.filter(name => !previousLeagueNames.includes(name));
                    Promise.all(addedLeagueNames.map(league => {
                        return API.post('/league/', {name: league, seasonId: res.data.id});
                    }))
                        .then(() => {
                            Promise.all(removedLeagues.map(league => {
                                return API.delete(`/league/${league.id}`);
                            }))
                                .then(() => {
                                    forceLeaguesUpdate();
                                    forceSeasonsUpdate();
                                    setIsSeasonPopupVisible(false);
                                })
                        })
                })
                .catch(reason => {
                    if (reason.response && reason.response.data) {
                        setErrorMessage(reason.response.data.message);
                    }
                });
        } else {
            const data = {
                name: selectedName,
                startDate: convertDateToIsoString(selectedStartDate),
                endDate: convertDateToIsoString(selectedEndDate)
            };

            API.post('/season/', data)
                .then(res => {
                    Promise.all(selectedLeagues.map(league => {
                        return API.post('/league/', {name: league, seasonId: res.data.id});
                    }))
                        .then(() => {
                            forceLeaguesUpdate();
                            forceSeasonsUpdate();
                            setIsSeasonPopupVisible(false);
                        })
                })
                .catch(reason => {
                    if (reason.response && reason.response.data) {
                        setErrorMessage(reason.response.data.message);
                    }
                });
        }

    };

    return (
        <Popup
            open={isSeasonPopupVisible}
            onClose={() => resetAndClose()} modal>
            {close => (
                <div>
                    <button className="close-modal" onClick={close}>
                        &times;
                    </button>
                    <div><br /></div>
                    <>
                        <h3 className={"m-3"}>
                            {selectedSeason ? 'Hooaja muutmine' : 'Hooaja lisamine'}
                        </h3>
                        <Form className="popup-form">
                            <Row>
                                <Col md={8} className={"offset-md-2"}>
                                    <Form.Group controlId="formSeasonName">
                                        <Form.Label>Hooaja nimi</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Sisesta nimi"
                                            value={selectedName}
                                            onChange={event => setSelectedName(event.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className={"offset-md-2"}>
                                    <Form.Group controlId="formSeasonStart">
                                        <Form.Label>Hooaja algus</Form.Label>
                                        <DatePicker
                                            className={selectedLeagues.length > 0 ? "ml-2 blue-grey lighten-5": "ml-2"}
                                            selected={selectedStartDate}
                                            locale={et}
                                            dateFormat="dd.MM.yyyy"
                                            onChange={date => setSelectedStartDate(date)}
                                            disabled={selectedLeagues.length > 0}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formSeasonEnd">
                                        <Form.Label>Hooaja lõpp</Form.Label>
                                        <DatePicker
                                            className={selectedLeagues.length > 0 ? "ml-2 blue-grey lighten-5": "ml-2"}
                                            selected={selectedEndDate}
                                            locale={et}
                                            dateFormat="dd.MM.yyyy"
                                            onChange={date => setSelectedEndDate(date)}
                                            disabled={selectedLeagues.length > 0}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className={"offset-md-2"}>
                                    <Form.Group>
                                        <Form.Label>Liiga(d)</Form.Label>
                                        <Row>
                                            <Col md={10}>
                                                <Form.Control
                                                    as="select"
                                                    placeholder="Vali liiga"
                                                    onChange={event => setSelectedLeagueOption(event.target.value)}
                                                >
                                                    {createLeagueNameOptions()}
                                                </Form.Control>
                                            </Col>
                                            <Col md={2}>
                                                <Button className={"mt-0"} variant="light"
                                                        onClick={() => addLeagueToSeason(selectedLeagueOption)}>
                                                    Lisa
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={10}>
                                                <Form.Control
                                                    className="mt-2"
                                                    type="text"
                                                    placeholder="Sisesta nimi"
                                                    onChange={event => setSelectedLeagueText(event.target.value)}
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Button variant="light" onClick={() => addLeagueToSeason(selectedLeagueText)}>
                                                    Lisa
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Form.Text className="text-muted">
                                            Vali eksisteeriv liiga või loo uus.
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className={"offset-md-2"}>
                                    <ListGroup>
                                        {createSelectedLeagueNameList()}
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Button className="mt-3" variant="primary" onClick={handleSave}>
                                Salvesta
                            </Button>

                            {errorMessage &&
                            <Alert variant="danger" className={"m-3"}>
                                {errorMessage}
                            </Alert>
                            }
                        </Form>
                    </>
                </div>
            )}

        </Popup>
    );
};