import React, {useState} from "react";
import {API} from "../../api";
import {Alert, Button, Card, Form} from "react-bootstrap";
import {useHistory} from "react-router";
import {isUserLoggedIn} from "./User";
import {renderSpinner} from "../../util/util";

export const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();


    const handleLogin = (formData) => {
        formData.preventDefault();
        const data = {
            username: username,
            password: password
        };
        setIsError(false);
        setIsLoading(true);
        API.post(`/auth/login`, data)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('user', response.data.username);
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('teamId', response.data.teamId);
                history.push("/admin");
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    };

    return (
        <div className={"d-flex flex-column align-items-center justify-content-center"} style={{minHeight: '80vh'}}>
            {isUserLoggedIn() ? history.push("/admin") : <div/>}
            <Card>
                <Card.Body>
                    <Form>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Kasutajanimi</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Sisesta kasutajanimi"
                                          value={username}
                                          onChange={event => setUsername(event.target.value)}/>
                        </Form.Group>

                        <Form.Group controlId="formPassword">
                            <Form.Label>Parool</Form.Label>
                            <Form.Control type="password"
                                          placeholder="Sisesta parool"
                                          value={password}
                                          onChange={event => setPassword(event.target.value)}/>
                        </Form.Group>
                        {isLoading ? renderSpinner() : <Button variant="primary" type="submit" onClick={handleLogin}>Logi sisse</Button>}
                    </Form>
                </Card.Body>
            </Card>

            {isError &&
            <Alert variant="danger" className={"mt-3"}>
                Sisselogimine ebaõnnestus! Palun kontrollige, kas kasutajanimi ja parool on õiged ja proovige
                uuesti.
            </Alert>
            }
        </div>
    );
};
