import React, {Component} from "react"


class SubstitutePlayerInput extends Component {
    SUBSTITUTIONS_TO_INITIALIZE = 4;

    render() {
        let {players, movedSubstitutions, teamId, number, substitutions} = this.props;
        let playerId = `subPlayerId-${number}`, gameNo = `subGameNo-${number}`, entered = `subEntered-${number}`;
        // necessary inputs: game no, sub player, entered
        // other info to incl. - team id.

        if (substitutions.length < this.SUBSTITUTIONS_TO_INITIALIZE) {
            return null;
        }
        players.sort((a, b) => a.firstName.localeCompare(b.firstName, 'et') || a.lastName.localeCompare(b.lastName, 'et'));
        movedSubstitutions.sort((a, b) => a.playerFirstName.localeCompare(b.playerFirstName, 'et') || a.playerLastName.localeCompare(b.playerLastName, 'et'));

        return <tr>
            <td>
                <input
                    type="number"
                    style={{}}
                    data-id={number}
                    name={gameNo}
                    data-teamid={teamId}
                    id={gameNo}
                    className="substitutionGameNo"
                    defaultValue={substitutions[number].substitutionGameNo}
                />
            </td>
            <td>
                <select
                    style={{}}
                    name={playerId}
                    data-id={number}
                    data-teamid={teamId}
                    id={playerId}
                    className="substitutionPlayer"
                    defaultValue={substitutions[number].substitutionPlayer}
                >
                    <option value=""/>
                    {players.map((player) =>
                        <option key={"" + {playerId} + "" + player.id}
                                value={player.id}>{player.firstName} {player.lastName}</option>
                    )}
                    {movedSubstitutions.map(movedSub =>
                        <option disabled key={"" + {playerId} + "" + movedSub.substitutionPlayer}
                                value={movedSub.substitutionPlayer}>{movedSub.playerFirstName} {movedSub.playerLastName}*</option>
                    )}
                </select>
            </td>
            <td>
                <input
                    type="number"
                    style={{}}
                    data-id={number}
                    data-teamid={teamId}
                    id={entered}
                    className="substitutionEntered"
                    defaultValue={substitutions[number].substitutionEntered}
                />
            </td>
        </tr>;
    }
}

export default SubstitutePlayerInput;