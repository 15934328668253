import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {Route, Switch} from "react-router-dom";
import {Seasons} from "./Seasons";
import {Statistics} from "./Statistics";
import {Admin} from "./Admin";
import Container from "react-bootstrap/Container";
import {LoginForm} from "./user/LoginForm";
import TeamDetails from "./TeamDetails";
import MatchDetails from "./MatchDetails";
import AddMatchResult from "./AddMatchResult";
import {Breadcrumbs} from "../modules/Breadcrumbs";
import {Clubs} from "./Clubs";
import {FutureMatches} from "./FutureMatches";


const MainContainer = () => {
    return (
        <Container fluid className="flex-container">
            <Switch>
                <Route path="/login">
                    <Container>
                        <LoginForm/>
                    </Container>
                </Route>
                <Route path="/admin/add_match">
                    <Container>
                        <Breadcrumbs/>
                        <AddMatchResult mode="NEW"/>
                    </Container>
                </Route>
                <Route path="/admin/edit_match">
                    <Container>
                        <Breadcrumbs/>
                        <AddMatchResult mode="EDIT"/>
                    </Container>
                </Route>
                <Route path="/clubs">
                    <Container>
                        <Breadcrumbs/>
                        <Clubs/>
                    </Container>
                </Route>
                <Route path="/future_matches">
                    <Container>
                        <Breadcrumbs/>
                        <FutureMatches/>
                    </Container>
                </Route>
                <Route path="/admin/match_details">
                    <Container>
                        <Breadcrumbs/>
                        <MatchDetails/>
                    </Container>
                </Route>
                <Route path="/admin">
                    <Container>
                        <Breadcrumbs/>
                        <Admin/>
                    </Container>
                </Route>
                <Route path="/team_details">
                    <Container>
                        <Breadcrumbs/>
                        <TeamDetails/>
                    </Container>
                </Route>
                <Route path="/match_details">
                    <Container>
                        <Breadcrumbs/>
                        <MatchDetails/>
                    </Container>
                </Route>
                <Route path="/statistics">
                    <Statistics/>
                </Route>
                <Route path="/">
                    <Container>
                        <Seasons/>
                    </Container>
                </Route>
            </Switch>
        </Container>
    );
};

export default MainContainer;