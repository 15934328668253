export const isUserLoggedIn = () => {
    return localStorage.getItem('token') !== null && localStorage.getItem('user') !== null;
};

export const getUserId = () => {
    return Number(localStorage.getItem('userId'));
};

export const getTeamId = () => {
    return Number(localStorage.getItem('teamId'));
};

export const getUsername = () => {
    return localStorage.getItem('user');
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('teamId');
};

export const isUserAdmin = () => {
    if (!isUserLoggedIn()) {
        return false;
    }
    const jwt = parseJwt(localStorage.getItem('token'));
    const adminRole = jwt.roles.find(role => role === 'ADMIN');
    return adminRole !== undefined;
};

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};
