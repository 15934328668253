import React, {useState} from 'react';
import Popup from "reactjs-popup";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {API} from "../api";
import {createTeamNameOptions} from "../util/util";

export const UserPopup = ({isUserPopupVisible, setIsUserPopupVisible, allTeams, forceUsersUpdate, users}) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [selectedTeam, setSelectedTeam] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const resetAndClose = () => {
        setUsername("");
        setPassword("");
        setSelectedTeam("");
        setErrorMessage("");
        return setIsUserPopupVisible(false);
    };

    function usernameAlreadyExists() {
        return users.find(user => user.username === username) !== undefined;
    }

    const handleSave = () => {
        if (!username || !password) {
            setErrorMessage("Kasutajanimi ja parool on kohustuslikud väljad");
            return;
        }
        if (!selectedTeam) {
            setErrorMessage("Kasutaja peab esindama võistkonda");
            return;
        }
        if (usernameAlreadyExists()) {
            setErrorMessage("Kasutajanimi on juba kasutusel");
            return;
        }
        const data = {
            username: username,
            password: password,
            teamId: selectedTeam
        };
        API.post('/user/', data)
            .then(() => {
                forceUsersUpdate();
                setIsUserPopupVisible(false);
            })
            .catch(reason => {
                if (reason.response && reason.response.data) {
                    setErrorMessage(reason.response.data.message);
                }
            });
    };

    return (
        <Popup
            open={isUserPopupVisible}
            onClose={() => resetAndClose()} modal>
            {close => (
                <div>
                    <button className="close-modal" onClick={close}>
                        &times;
                    </button>
                    <div><br /></div>
                    <>
                        <h3 className={"m-3"}>Kasutaja lisamine</h3>
                        <Form className="popup-form">
                            <Row>
                                <Col md={8} className={"offset-md-2"}>
                                    <Form.Group controlId="formUsername">
                                        <Form.Label>Kasutajanimi</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Sisesta kasutajanimi"
                                            value={username}
                                            onChange={event => setUsername(event.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className={"offset-md-2"}>
                                    <Form.Group controlId="formPassword">
                                        <Form.Label>Parool</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Sisesta parool"
                                            value={password}
                                            onChange={event => setPassword(event.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className={"offset-md-2"}>
                                    <Form.Label>Vali esindatav võistkond</Form.Label>
                                    <Form.Control
                                        as="select"
                                        placeholder="Vali võistkond"
                                        onChange={event => setSelectedTeam(event.target.value)}
                                    >
                                        {createTeamNameOptions(allTeams)}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Button className="mt-3" variant="primary" onClick={handleSave}>
                                Salvesta
                            </Button>

                            {errorMessage &&
                            <Alert variant="danger" className={"m-3"}>
                                {errorMessage}
                            </Alert>
                            }

                        </Form>
                    </>
                </div>
            )}

        </Popup>
    );
};