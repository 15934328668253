import React, {Component} from "react";
import '../style/MatchDetails.css';
import {API} from "../api";
import {MatchDetailsTable} from "../modules/MatchDetailsTable";
import {getUrlVars} from "../util/util";
import {createDateTimeFromBackendDate, formatBackendDateTimeEtLocale, getCurrentDateMinusDays} from "../util/dateUtil";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getTeamId, isUserAdmin, isUserLoggedIn} from "./user/User";
import Form from "react-bootstrap/Form";


class MatchDetails extends Component {
    state = {
        matchData: [],
    };

    componentDidMount() {
        API.get(`/match/${getUrlVars()["match_id"]}`)
            .then(res => {
                this.setState({matchData: res.data});
            })
    }

    render() {
        if (!this.state.matchData.id) {
            return null;
        }

        const matchData = this.state.matchData;

        return (
            <div>
                <div className={"d-flex align-items-end flex-column"}>
                    <span>Koht: {matchData.location}</span>
                    <span>Kuupäev: {formatBackendDateTimeEtLocale(matchData.matchDateTime)}</span>
                    {matchData.isMatchAnnulled ?
                        <span>Matš annulleeritud: {this.matchAnnulledCheckbox(matchData.isMatchAnnulled)}</span>
                        : null}
                </div>
                <div>
                    <div className="wrapper">
                        <div className="home_player">
                            <h4 className="">{matchData.homeTeamName}</h4>
                        </div>
                        <div className="guest_player">
                            <h4 className="">{matchData.awayTeamName}</h4>
                        </div>
                    </div>
                    <MatchDetailsTable matchData={matchData}/>
                    {matchData.homePoints || matchData.awayPoints ?
                        <>
                            <h3>Lõpptulemus</h3>
                            <h3>{matchData.homePoints} : {matchData.awayPoints}</h3>
                        </>
                        : null}
                </div>
                <Link to={`/admin/edit_match?match_id=${getUrlVars()["match_id"]}`}>
                    {
                        (isUserAdmin() || this.isUserWhoIsInHomeOrAwayTeam(matchData))
                        && <Button variant="primary">Muuda andmeid</Button>
                    }
                </Link>
            </div>
        );
    }

  isUserWhoIsInHomeOrAwayTeam(matchData) {
    return isUserLoggedIn()
      && (getTeamId() === matchData.homeTeamId || getTeamId() === matchData.awayTeamId)
      && createDateTimeFromBackendDate(matchData.matchDateTime) > getCurrentDateMinusDays(5);
  }

    matchAnnulledCheckbox(isMatchAnnulled) {
        return (
            <Form.Check
                custom
                type={`checkbox`}
                label={``}
                defaultChecked={isMatchAnnulled}
                readOnly={true}
                disabled
                inline={true}
            />
        )
    }
}

export default MatchDetails;
