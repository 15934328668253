import React from 'react';
import DataTable from "../modules/datatable/Datatable";
import '../style/Tabs.css';
import {API} from "../api";
import {getUrlVars, renderSpinner} from "../util/util";


export default class TeamDetails extends React.Component {
    state = {
        seasonTeamData: [],
        isLoading: true,
    };

    componentDidMount() {
        const teamId = getUrlVars()["team_id"];
        const seasonId = getUrlVars()["season_id"];
        API.get(`/team/${teamId}/season/${seasonId}`).then(res => {
            this.setState({seasonTeamData: {teamData: res.data, team: teamId, season: seasonId}});
        }).finally(() => this.setState({isLoading: false}));
    }

    render() {
        if (this.state.isLoading) {
            return renderSpinner();
        }
        if (!this.state.seasonTeamData) {
            return null;
        }
        const seasonTeamData = this.state.seasonTeamData;

        return (
            <div>
                {seasonTeamData.teamData ?
                    <div>
                        <p>Võistkond: {seasonTeamData.teamData.name}</p>
                        <p>Mängud</p>
                        <DataTable matches={seasonTeamData.teamData.matches} currentTeamName={seasonTeamData.teamData.name}/>
                    </div>
                    : null
                }
            </div>
        )
    }
}
