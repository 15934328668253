import {Button, Col, Form, ListGroup, Row} from "react-bootstrap";
import DatePicker from "react-datepicker/es";
import {convertDateToIsoString, convertIsoStringToDateWithZeroTime} from "../util/dateUtil";
import {et} from "date-fns/esm/locale";
import React, {useState} from "react";

export const PlayerItem = ({selectedPlayer, changeTeamCaptain, removePlayerFromTeam, updatePlayerInfo}) => {

    const [showEdit, setShowEdit] = useState(false);

    const [selectedFirstName, setSelectedFirstName] = useState(selectedPlayer.firstName);
    const [selectedLastName, setSelectedLastName] = useState(selectedPlayer.lastName);
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(convertIsoStringToDateWithZeroTime(selectedPlayer.dateOfBirth));
    const [selectedGender, setSelectedGender] = useState(selectedPlayer.gender);

    const updatePlayer = () => {
        const player = {
            id: selectedPlayer.id,
            firstName: selectedFirstName,
            lastName: selectedLastName,
            gender: selectedGender,
            dateOfBirth: convertDateToIsoString(selectedDateOfBirth)
        };
        updatePlayerInfo(player);
        setShowEdit(false);
    };

    return (
        <>
            <ListGroup.Item
                className="d-flex justify-content-between align-items-center"
                key={selectedPlayer.id + selectedPlayer.firstName + selectedPlayer.lastName + "-groupItem"}
            >
                {selectedPlayer.firstName + " " + selectedPlayer.lastName} {selectedPlayer.isCaptain && " (K)"}
                <div>
                    {!selectedPlayer.isCaptain &&
                    <Button
                        variant={'light'}
                        onClick={() => changeTeamCaptain(selectedPlayer)}
                    >
                        Muuda kapteniks
                    </Button>
                    }
                    <Button
                        variant={'light'}
                        onClick={() => setShowEdit(true)}
                        disabled={showEdit}
                    >
                        Muuda andmeid
                    </Button>
                    <Button
                        variant={'light'}
                        onClick={() => removePlayerFromTeam(selectedPlayer)}
                    >
                        Eemalda
                    </Button>
                </div>
            </ListGroup.Item>
            {showEdit && renderEdit()}
        </>
    )

    function renderEdit() {
        return (
            <Row>
                <Col xl={3}>
                    <Form.Control
                        className="mt-2 mb-2"
                        type="text"
                        placeholder="Sisesta eesnimi"
                        value={selectedFirstName}
                        onChange={event => setSelectedFirstName(event.target.value)}
                    />
                </Col>
                <Col xl={3}>
                    <Form.Control
                        className="mt-2 mb-2"
                        type="text"
                        placeholder="Sisesta perenimi"
                        value={selectedLastName}
                        onChange={event => setSelectedLastName(event.target.value)}
                    />
                </Col>
                <Col xl={2}>
                    <DatePicker
                        className="mt-2 mb-2"
                        selected={selectedDateOfBirth}
                        locale={et}
                        dateFormat="dd.MM.yyyy"
                        placeholderText="Sünnikuupäev"
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={new Date()}
                        onChange={date => setSelectedDateOfBirth(date)}
                    />
                </Col>
                <Col xl={2}>
                    <Form.Control
                        className="mt-2 mb-2"
                        as="select"
                        placeholder="Vali sugu"
                        value={selectedGender}
                        onChange={event => setSelectedGender(event.target.value)}
                    >
                        <option key={"male-option"} value="MALE">Mees</option>
                        <option key={"female-option"} value="FEMALE">Naine</option>
                    </Form.Control>
                </Col>
                <Col xl={2}>
                    <Button
                        className="mt-2 mb-2"
                        variant="light"
                        onClick={() => updatePlayer()}
                        disabled={!selectedFirstName || !selectedLastName || !selectedDateOfBirth}
                    >
                        Uuenda
                    </Button>
                </Col>
            </Row>
        );
    }

};
